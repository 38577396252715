/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useMemo, useState } from 'react';
import cn from 'classnames';
// import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import StackedBarChart from '../../components/charts/StackedBarChart';
// import PropTypes from 'prop-types';
// import cn from 'classnames';
import styles from './styles.module.scss';
import formatNumber from '../../utils/number';
import DownloadChartIcon from '../../components/icons/DownloadChart';
import actionsAssets from '../../redux/actions/assets';

const getFileName = (title, asset, period) => {
  const fileName = `${asset} ${title} ${period}`;
  return fileName.replaceAll(' ', '_');
};

function Financials({ companyInfo, chartData, customCharts, asset }) {
  const [period, setPeriod] = useState('quarterly');
  const incomeChart = useMemo(() => chartData[1], [chartData[1]]);
  const netIncome = useMemo(() => chartData[2], [chartData[2]]);
  const marginsChart = useMemo(() => chartData[3], [chartData[3]]);
  const expensesChart = useMemo(() => chartData[4], [chartData[4]]);
  const growthChart = useMemo(() => chartData[5], [chartData[5]]);
  const cashFlow = useMemo(() => chartData[6], [chartData[6]]);
  const cashChart = useMemo(() => chartData[7], [chartData[7]]);
  const outstandingShares = useMemo(() => chartData[8], [chartData[8]]);
  const marketCap = useMemo(() => chartData[9], [chartData[9]]);
  const revenueMultiply = useMemo(() => chartData[10], [chartData[10]]);
  const onDownload = useCallback((id, fileName) => actionsAssets.downloadFile({ code: asset, id, fileName, period }));
  return (
    <>
      <div className={styles.overviewInfo}>
        <div>
          <Typography variant="caption">TTM Revenue</Typography>
          <Typography variant="subtitle1">
            {`$ ${Intl.NumberFormat('en-GB', {
              notation: 'compact',
              minimumFractionDigits: 2,
            }).format(companyInfo?.revenue_ttm)}`}
          </Typography>
        </div>
        <div>
          <Typography variant="caption">TTM Revenue Multiple</Typography>
          <Typography variant="subtitle1">{`${formatNumber(companyInfo?.price_sales_ttm)}X`}</Typography>
        </div>
        <div>
          <Typography variant="caption">Revenue Growth Y / Y</Typography>
          <Typography variant="subtitle1">{`${companyInfo?.revenue_growth_yoy ? companyInfo.revenue_growth_yoy * 100 : 0} %`}</Typography>
        </div>
        <div>
          <Typography variant="caption">Most Recent Quarter</Typography>
          <Typography variant="subtitle1">
            FY22 Q3 / 22 Q2
            {' '}
            <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M6.65625 6.45504C6.65625 6.27375 6.50941 6.12691 6.32812 6.12691H5.67187C5.49059 6.12691 5.34375 6.27375 5.34375 6.45504V8.75219H4.00719C3.71434 8.75219 3.56832 9.10684 3.77613 9.31328L5.75336 11.2757C5.8898 11.4111 6.10992 11.4111 6.24637 11.2757L8.22359 9.31328C8.43141 9.10711 8.28539 8.75219 7.99254 8.75219H6.65625V6.45504ZM10.8625 2.67914L8.56867 0.385547C8.32258 0.139453 7.98898 0 7.64199 0H2.06223C1.33789 0.00273437 0.75 0.590625 0.75 1.31496V12.6878C0.75 13.4121 1.33789 14 2.06223 14H9.93559C10.6602 14 11.25 13.4121 11.25 12.6878V3.60855C11.25 3.26156 11.1086 2.92523 10.8625 2.67914ZM7.74863 1.4216L9.82922 3.50191H7.74863V1.4216V1.4216ZM9.9375 12.6878H2.06223V1.31496H6.43641V4.15816C6.43641 4.52184 6.72898 4.81414 7.09266 4.81414H9.9375V12.6878Z" fill="#00BEB8" />
            </svg>
          </Typography>
        </div>
      </div>
      <div className={styles.filters}>
        <div
          className={cn({
            [styles.active]: period === 'quarterly',
          })}
          onClick={() => setPeriod('quarterly')}
        >
          Quarterly
        </div>
        <div
          className={cn({
            [styles.active]: period === 'yearly',
          })}
          onClick={() => setPeriod('yearly')}
        >
          Annual
        </div>
      </div>

      {incomeChart?.x_axis && incomeChart?.x_axis[period] && (
        <div className={styles.chartWrapper} style={{ marginTop: 40 }}>
          <StackedBarChart period={period} chartData={incomeChart} />
          <IconButton onClick={() => onDownload(1, getFileName(asset, incomeChart.title, period))}>
            <DownloadChartIcon />
          </IconButton>
        </div>
      )}
      {netIncome?.x_axis && netIncome?.x_axis[period] && (
      <div className={styles.chartWrapper} style={{ marginTop: 40 }}>
        <StackedBarChart period={period} chartData={netIncome} hideLegend />
        <IconButton onClick={() => onDownload(2, getFileName(asset, netIncome.title, period))}>
          <DownloadChartIcon />
        </IconButton>
      </div>
      )}
      {marginsChart?.x_axis && marginsChart?.x_axis[period] && (
      <div className={styles.chartWrapper} style={{ marginTop: 40 }}>
        <StackedBarChart period={period} chartData={marginsChart} hideLegend />
        <IconButton onClick={() => onDownload(3, getFileName(asset, marginsChart.title, period))}>
          <DownloadChartIcon />
        </IconButton>
      </div>
      )}
      {expensesChart?.x_axis && expensesChart?.x_axis[period] && (
      <div className={styles.chartWrapper} style={{ marginTop: 40 }}>
        <StackedBarChart period={period} chartData={expensesChart} hideLegend />
        <IconButton onClick={() => onDownload(4, getFileName(asset, expensesChart.title, period))}>
          <DownloadChartIcon />
        </IconButton>
      </div>
      )}
      {growthChart?.x_axis && growthChart?.x_axis[period] && (
        <div className={styles.chartWrapper} style={{ marginTop: 40 }}>
          <StackedBarChart period={period} chartData={growthChart} hideLegend />
          <IconButton onClick={() => onDownload(5, getFileName(asset, growthChart.title, period))}>
            <DownloadChartIcon />
          </IconButton>
        </div>
      )}
      {cashFlow?.x_axis && cashFlow?.x_axis[period] && (
        <div className={styles.chartWrapper} style={{ marginTop: 40 }}>
          <StackedBarChart period={period} chartData={cashFlow} hideLegend />
          <IconButton onClick={() => onDownload(6, getFileName(asset, cashFlow.title, period))}>
            <DownloadChartIcon />
          </IconButton>
        </div>
      )}
      {cashChart?.x_axis && cashChart?.x_axis[period] && (
        <div className={styles.chartWrapper} style={{ marginTop: 40 }}>
          <StackedBarChart period={period} chartData={cashChart} hideLegend />
          <IconButton onClick={() => onDownload(7, getFileName(asset, cashChart.title, period))}>
            <DownloadChartIcon />
          </IconButton>
        </div>
      )}
      {outstandingShares?.x_axis && outstandingShares?.x_axis[period] && (
        <div className={styles.chartWrapper} style={{ marginTop: 40 }}>
          <StackedBarChart period={period} chartData={outstandingShares} hideLegend />
          <IconButton onClick={() => onDownload(8, getFileName(asset, outstandingShares.title, period))}>
            <DownloadChartIcon />
          </IconButton>
        </div>
      )}
      {marketCap?.x_axis && marketCap?.x_axis[period] && (
        <div className={styles.chartWrapper} style={{ marginTop: 40 }}>
          <StackedBarChart period={period} chartData={marketCap} hideLegend />
          <IconButton onClick={() => onDownload(9, getFileName(asset, marketCap.title, period))}>
            <DownloadChartIcon />
          </IconButton>
        </div>
      )}
      {revenueMultiply?.x_axis && revenueMultiply?.x_axis[period] && (
        <div className={styles.chartWrapper} style={{ marginTop: 40 }}>
          <StackedBarChart period={period} chartData={revenueMultiply} hideLegend />
          <IconButton onClick={() => onDownload(10, getFileName(asset, revenueMultiply.title, period))}>
            <DownloadChartIcon />
          </IconButton>
        </div>
      )}
      {customCharts.map((chart) => (
        <div className={styles.chartWrapper} style={{ marginTop: 40 }}>
          <StackedBarChart chartData={chart} />
          <IconButton onClick={() => onDownload(chart.chartId, getFileName(asset, chart.title, period))}>
            <DownloadChartIcon />
          </IconButton>
        </div>
      ))}
      {/*
        <Box key="test2" padding={3}>
          <StackedBarChart type="line" />
        </Box>
        <Box key="test3" padding={3}>
          <StackedBarChart type="bar" />
        </Box> */}
    </>

  );
}

Financials.propTypes = {

};
export default Financials;
