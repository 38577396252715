/*eslint-disable*/
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import AdminLayout from '../../components/AdminLayout';
import FormEditCustomProp from '../../components/forms/EditCustomProp';
import { customChartsSelectors } from '../../redux/selectors/customCharts';
import actionsCustomCharts from '../../redux/actions/customCharts';

function EditCustomProp() {
  const { id } = useParams();
  const selectedChart = useSelector(customChartsSelectors.getSelectedCustomChart);
  const chartTypes = useSelector(customChartsSelectors.getChartTypes);

  useEffect(() => {
    actionsCustomCharts.getCustomChartById(id);
  }, [id]);

  useEffect(() => {
    if (!chartTypes.length) {
      actionsCustomCharts.getChartTypes();
    }
  }, []);

  return (
    <AdminLayout>
      {selectedChart && <FormEditCustomProp selectedChart={selectedChart}/>}
    </AdminLayout>
  );
}

EditCustomProp.propTypes = {};
export default EditCustomProp;
