import { createSelector } from '@reduxjs/toolkit';

const customChartsState = (state) => state.customCharts;

const getChartTypes = createSelector(
  customChartsState,
  (state) => state.chartTypes,
);

const getCustomCharts = createSelector(
  customChartsState,
  (state) => state.customCharts,
);

const getSelectedCustomChart = createSelector(
  customChartsState,
  (state) => state.selectedCustomChart,
);

const getCount = createSelector(
  customChartsState,
  (state) => state.count,
);

const getSelectedPage = createSelector(
  customChartsState,
  (state) => state.selectedPage,
);

// eslint-disable-next-line import/prefer-default-export
export const customChartsSelectors = {
  getChartTypes,
  getCustomCharts,
  getSelectedCustomChart,
  getCount,
  getSelectedPage,
};
