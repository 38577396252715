import Api from '../../utils/Api';
import apiURL from '../../constants/apiURL';
import sliceProfile from '../slices/sliceProfile';
import { dispatchAction } from '../store';

const actionsProfile = {};

actionsProfile.getFavorites = () => Api.get({
  url: apiURL.getFavorites(),
  action: sliceProfile.actions.getFavorites,
});

actionsProfile.getFavoriteNews = (
  page = 1,
  size = 10,
) => Api.get({
  url: apiURL.getFavoriteNews({ page, size }),
  action: sliceProfile.actions.setNews,
  withHeaders: true,
}).then((response) => {
  dispatchAction(sliceProfile.actions.setCount, Number(response.headers['x-total-pages']));
  dispatchAction(sliceProfile.actions.setSelectedPage, Number(page));
});

actionsProfile.addFavorite = (requestData) => Api.post({
  url: apiURL.addFavorites(),
  action: sliceProfile.actions.addFavorite,
  requestData,
}).then((response) => {
  actionsProfile.getFavorites();
  return response;
});

actionsProfile.removeFavorite = (requestData) => Api.delete({
  url: apiURL.removeFavorites(),
  action: sliceProfile.actions.removeFavorite,
  requestData,
}).then((response) => {
  actionsProfile.getFavorites();
  return response;
});

export default actionsProfile;
