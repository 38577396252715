import React from 'react';

function ToRightIcon() {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_0_231)">
        <path fillRule="evenodd" clipRule="evenodd" d="M21.2531 17.1094L25.8906 21.7344C26.0375 21.8813 26.0375 22.1188 25.8906 22.2656L21.2531 26.8906C21.1063 27.0375 20.8688 27.0375 20.7219 26.8906L20.1094 26.2781C19.9594 26.1281 19.9625 25.8875 20.1156 25.7437L23.15 22.8156H15.375C15.1688 22.8156 15 22.6469 15 22.4406V21.5656C15 21.3594 15.1688 21.1906 15.375 21.1906H23.15L20.1156 18.2625C19.9656 18.1156 19.9625 17.875 20.1094 17.7281L20.7219 17.1156C20.8688 16.9625 21.1063 16.9625 21.2531 17.1094ZM27.375 16.375V27.625C27.375 27.8313 27.5437 28 27.75 28H28.625C28.8313 28 29 27.8313 29 27.625V16.375C29 16.1688 28.8313 16 28.625 16H27.75C27.5437 16 27.375 16.1688 27.375 16.375Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_0_231">
          <rect width="44" height="44" fill="white" />
        </clipPath>
      </defs>
    </svg>

  );
}

export default ToRightIcon;
