/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  favorites: [],
  news: [],
  count: 0,
  selectedPage: 1,
};

const sliceProfile = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    getFavorites(state, action) {
      state.favorites = action.payload;
    },
    addFavorite(state, action) {},
    removeFavorite(state, action) {},
    setCount(state, action) {
      state.count = action.payload;
    },
    setSelectedPage(state, action) {
      state.selectedPage = action.payload;
    },
    setNews(state, action) {
      state.news = action.payload;
    },
  },
});

export const selectorsProfile = {
  get: (state) => state.profile,
  favorites: (state) => state.profile.favorites,
  news: (state) => state.profile.news,
  newsCount: (state) => state.profile.count,
  newsPage: (state) => state.profile.selectedPage,
};

export const reducerProfile = sliceProfile.reducer;
export default sliceProfile;
