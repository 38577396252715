/* eslint-disable camelcase */
const BASE_API_ENDPOINT = process.env.REACT_APP_API_URL;

const adminApiURL = {
  authSignIn: () => `${BASE_API_ENDPOINT}admin/login/`,
  getUsers: ({
    q,
    page,
    size,
    order_by,
    desc,
  }) => `${BASE_API_ENDPOINT}admin/users/?q=${q}&page=${page}&size=${size}&order_by=${order_by}&desc=${desc}`,
  getUserById: (id) => `${BASE_API_ENDPOINT}admin/users/${id}/`,
  updateUserById: (id) => `${BASE_API_ENDPOINT}admin/users/${id}/`,
  getCompanies: ({
    q,
    page,
    size,
    order_by,
    desc,
  }) => `${BASE_API_ENDPOINT}admin/assets/?q=${q}&page=${page}&size=${size}&order_by=${order_by}&desc=${desc}`,
  getCompanyByCode: (code) => `${BASE_API_ENDPOINT}admin/assets/${code}/`,
  getChartTypes: () => `${BASE_API_ENDPOINT}admin/chart_types/`,
  getCustomCharts: ({
    q,
    page,
    size,
    order_by,
    desc,
  }) => `${BASE_API_ENDPOINT}admin/custom_charts/?q=${q}&page=${page}&size=${size}&order_by=${order_by}&desc=${desc}`,
  createCustomChart: () => `${BASE_API_ENDPOINT}admin/custom_charts/`,
  getCustomChartById: (id) => `${BASE_API_ENDPOINT}admin/custom_charts/${id}`,
  updateCustomChartById: (id) => `${BASE_API_ENDPOINT}admin/custom_charts/${id}`,
  deleteCustomChartById: (id) => `${BASE_API_ENDPOINT}admin/custom_charts/${id}`,
  updateCustomChartActivityById: (id) => `${BASE_API_ENDPOINT}admin/custom_charts/${id}`,
  logout: () => `${BASE_API_ENDPOINT}admin/logout/`,
};

export default adminApiURL;
