/* eslint-disable max-len */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  list: [],
  fullList: [],
  details: {},
  charts: {},
  news: [],
  price: {},
  customCharts: [],
  autocompleteResults: [],
  count: 0,
  selectedPage: 1,
  compareChartsData: {},
  isLoading: false,
  newsCount: 0,
  newsSelectedPage: 1,
};

const sliceAssets = createSlice({
  name: 'assets',
  initialState,
  reducers: {
    setList(state, action) {
      state.list = action.payload;
    },
    setDetails(state, action) {
      state.details = action.payload;
    },
    setCharts(state, action) {
      state.charts[action.payload.chartId] = action.payload;
    },
    setNews(state, action) {
      state.news = action.payload;
    },
    setPriceChart(state, action) {
      state.price[action.payload.period] = action.payload;
    },
    setCustomCharts(state, action) {
      state.customCharts[action.payload.chartId] = action.payload;
    },
    setAutocomplete(state, action) {
      state.autocompleteResults = action.payload;
    },
    resetSearch(state) {
      state.autocompleteResults = [];
    },
    setFullList(state, action) {
      state.fullList = action.payload;
    },
    setCount(state, action) {
      state.count = action.payload;
    },
    setSelectedPage(state, action) {
      state.selectedPage = action.payload;
    },
    setCompareChartsData(state, action) {
      if (state.compareChartsData[action.payload.code]) { state.compareChartsData[action.payload.code][action.payload.chartId] = action.payload; }
    },
    prepareCompareChartData(state, action) {
      state.compareChartsData[action.payload.code] = {};
    },
    removeCompareData(state, action) {
      const newData = state.compareChartsData;
      delete newData[action.payload.code];
      state.compareChartsData = newData;
    },
    setIsLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    setNewsCount(state, action) {
      state.newsCount = action.payload;
    },
    setNewsSelectedPage(state, action) {
      state.newsSelectedPage = action.payload;
    },
    resetCustomCharts(state) {
      state.customCharts = [];
    },
    updateFavorite(state, action) {
      state.fullList = state.fullList.map((company) => {
        if (company.code === action.payload.asset) {
          company.is_favorite = !company.is_favorite;
        }
        return company;
      });
      state.list = state.list.map((company) => {
        if (company.code === action.payload.asset) {
          company.is_favorite = !company.is_favorite;
        }
        return company;
      });
    },
  },
});

export const selectorsAsssets = {
  get: (state) => state.assets,
  list: (state) => state.assets.list,
  fullList: (state) => state.assets.fullList,
  details: (state) => state.assets.details,
  charts: (state) => state.assets.charts,
  news: (state) => state.assets.news,
  price: (state) => state.assets.price,
  customCharts: (state) => state.assets.customCharts,
  autocompleteResults: (state) => state.assets.autocompleteResults,
  assetsCount: (state) => state.assets.count,
  assetsPage: (state) => state.assets.selectedPage,
  compareChartsData: (state) => state.assets.compareChartsData,
  isLoading: (state) => state.assets.isLoading,
  newsCount: (state) => state.assets.newsCount,
  newsPage: (state) => state.assets.newsSelectedPage,
};

export const reducerAssets = sliceAssets.reducer;
export default sliceAssets;
