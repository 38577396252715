import { useCallback, useMemo } from 'react';
import * as Yup from 'yup';

const useValidation = ({ scheme, requiredFields }) => {
  const schemaData = useMemo(() => {
    const data = { ...scheme };
    Object.keys(data).forEach((field) => {
      if (requiredFields[field]) {
        data[field] = data[field].clone().required('This field is required');
      } else {
        data[field] = data[field].clone();
      }
    });
    return Yup.object().shape(data);
  }, [requiredFields, scheme]);

  const isRequired = useCallback(
    (name) => requiredFields[name],
    [requiredFields],
  );

  return {
    scheme: schemaData,
    isRequired,
  };
};

export default useValidation;
