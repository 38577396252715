/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
// import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import Layout from '../../components/Layout';
// import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import actionsCalendar from '../../redux/actions/calendar';
import { selectorsCalendar } from '../../redux/slices/sliceCalendar';
import { parseRoutePath } from '../../utils/routing';
import routes from '../../constants/routes';
import { selectorsAuth } from '../../redux/slices/sliceAuth';

function formatDate(date, format) {
  const map = {
    mm: date.getMonth() + 1,
    dd: date.getDate(),
    yyyy: date.getFullYear(),
  };
  console.log(map, format);

  return format.replace(/mm|dd|yyyy/gi, (matched) => map[matched]);
}

function CalendarPage() {
  const calendarData = useSelector(selectorsCalendar.calendar);
  const isAuth = useSelector(selectorsAuth.isAuth);

  useEffect(() => {
    const dateFrom = new Date();
    const dateTo = new Date();
    dateFrom.setTime(dateFrom.getTime() - (10 * 24 * 3600000));
    dateTo.setTime(dateTo.getTime() + (30 * 24 * 3600000));
    console.log(dateFrom, dateTo);
    actionsCalendar.getList({ dateFrom: formatDate(dateFrom, 'yyyy-mm-dd'), dateTo: formatDate(dateTo, 'yyyy-mm-dd') });
  }, [isAuth]);

  return (
    <Layout>
      <div className={styles.newsWrapper}>
        <Typography variant="h5">Earnings Calendar</Typography>
        {Object.keys(calendarData).map((key) => (
          <div className={styles.newsItem}>
            <Typography className={styles.title} variant="subtitle1">{key}</Typography>
            <div className={styles.companiesList}>
              {calendarData[key].map((company) => (
                <Link
                  className={cn(styles.company, {
                    [styles.favorite]: company.asset.is_favorite,
                  })}
                  to={parseRoutePath(routes.COMPANY_PAGE, { asset: company.asset.code })}
                >
                  {company.asset.name}
                  <span>{company.asset.code}</span>
                </Link>
              ))}

            </div>
          </div>
        ))}
      </div>
    </Layout>

  );
}

CalendarPage.propTypes = {

};
export default CalendarPage;
