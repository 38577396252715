/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import { KEY_TOKEN } from '../../utils/apiService';

const initialProfile = {};

const initialState = {
  init: false,
  isAuthorized: !!localStorage.getItem(KEY_TOKEN),
  profile: initialProfile,
};

const sliceAuth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setInit(state, action) {
      state.init = true;
    },
    getProfile(state, action) {
      state.profile = {
        ...action.payload,
      };
    },
    updateProfile(state, action) {
      state.profile = { ...state.profile, ...action.payload };
    },
    signIn(state, action) {
      state.isAuthorized = true;
      state.profile = { ...state.profile, ...action.payload.user };
    },
    signUp(state, action) {},
    confirmEmail(state, action) {},
    signOut(state) {
      state.isAuthorized = false;
      state.profile = initialProfile;
    },
    forgotPassword(state, action) {},
    changePassword(state, action) {},
    resetPassword(state, action) {},
    resendEmail(state, action) {},
    refreshToken(state) {
      state.isAuthorized = true;
    },
  },
  extraReducers: {},
});

export const selectorsAuth = {
  get: (state) => state.auth,
  isInit: (state) => state.auth.init,
  isAuth: (state) => state.auth.isAuthorized,
  getProfile: (state) => state.auth.profile,
  getSettings: (state) => state.auth.settings,
};

export const reducerAuth = sliceAuth.reducer;
export default sliceAuth;
