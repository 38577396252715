/* eslint-disable max-len */
const options = (yaxis, xaxis, title, hideLegend, unit) => {
  const baseOptions = {
    color: [
      '#5297D4',
      '#E76051',
      '#E4C15A',
      '#64C686',
      '#BA5DD6',
    ],
    title: {
      text: title,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    legend: {
      // Try 'horizontal'
      width: '70%',
      right: '10%',
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        data: xaxis.data || ['FY18 Q4', 'FY18 Q4', 'FY18 Q4', 'FY18 Q4', 'FY18 Q4', 'FY18 Q4', 'FY18 Q4', 'FY18 Q4', 'FY18 Q4', 'FY18 Q4', 'FY18 Q4', 'FY18 Q4', 'FY18 Q4', 'FY18 Q4'],
      },
    ],
    yAxis: [
      {
        type: 'value',

        axisLabel: {
          formatter(value) {
            return `${Intl.NumberFormat('en-GB', {
              notation: 'compact',
            }).format(value)} ${unit || '$'}`;
          },
        },
      },
    ],
    series: [],
  };
  if (yaxis && yaxis.length > 0) {
    yaxis.map((item) => baseOptions.series.push({
      name: item.label,
      stack: item.group || 'Data',
      type: 'bar',
      tooltip: {
        valueFormatter(value) {
          return `${Intl.NumberFormat('en-GB', {
            notation: 'compact',
            minimumFractionDigits: 2,
          }).format(value)} ${unit || '$'}`;
        },
      },
      data: item.data || [120, 132, 101, 134, 90, 230, 210, 120, 132, 101, 134, 90, 230, 210],
    }));
  } else {
    baseOptions.series.push({
      name: 'Price',
      stack: 'Ad',
      type: 'bar',
      tooltip: {
        valueFormatter(value) {
          return `${Intl.NumberFormat('en-GB', {
            notation: 'compact',
            minimumFractionDigits: 2,
          }).format(value)} ${unit || '$'}`;
        },
      },
      data: [120, 132, 101, 134, 90, 230, 210, 120, 132, 101, 134, 90, 230, 210],
    });
  }
  return baseOptions;
};

export default options;
