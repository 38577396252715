/* eslint-disable react/prop-types */
import * as React from 'react';
import { useCallback } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { Button, Chip } from '@mui/material';
import { toast } from 'react-toastify';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { ModalContext } from '../../context/ModalContext';
import actionsCustomCharts from '../../redux/actions/customCharts';
import { customChartsSelectors } from '../../redux/selectors/customCharts';

import styles from './styles.module.scss';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(
  order,
  orderBy,
) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    order,
    orderBy,
    onRequestSort,
    columns,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow className={styles.tableRow}>
        {columns.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sort ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.name}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : headCell.name}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function EditChartModal() {
  const {
    props,
    hideModal,
  } = React.useContext(ModalContext);
  const { row } = props;

  const handleSubmit = useCallback(
    (id) => {
      actionsCustomCharts
        .deleteChart(id)
        .then(() => {
          actionsCustomCharts.getCustomCharts({ size: 5 });
          toast.success('Chart has been successfully deleted!');
          hideModal();
        })
        .catch((error) => {
          if (error?.detail) {
            toast.error(error?.detail);
          }
        });
    },
    [hideModal],
  );

  return (
    <>
      <Typography variant="h4">
        Are you sure to delete chart?
      </Typography>
      <div className={styles.buttonsWrapper}>
        <Button
          color="success"
          margin="center"
          variant="contained"
          onClick={() => hideModal()}
        >
          Cancel
        </Button>
        <Button
          color="error"
          margin="center"
          variant="contained"
          onClick={() => handleSubmit(row.id)}
        >
          Delete
        </Button>
      </div>
    </>
  );
}

export default function AdminCustomChartsTable({
  customCharts,
  columns,
}) {
  const { showModal } = React.useContext(ModalContext);
  const chartTypes = useSelector(customChartsSelectors.getChartTypes);
  const navigate = useNavigate();

  const getChartName = (id) => chartTypes.find((el) => el.id === id);

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('title');

  const handleRequestSort = (
    event,
    property,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <TableContainer>
        <Table
          sx={{ minWidth: 1050 }}
          aria-labelledby="tableTitle"
          size="medium"
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            columns={columns}
          />
          <TableBody>
            {stableSort(customCharts, getComparator(order, orderBy))
              .map((row) => (
                <TableRow key={row?.name} className={styles.tableRow}>
                  <TableCell align="center">
                    {row?.id}
                  </TableCell>
                  <TableCell align="center">
                    {row?.title}
                  </TableCell>

                  <TableCell align="center">
                    {row?.assets.name}
                    {' '}
                    {`(${row?.assets.code})`}
                  </TableCell>

                  <TableCell align="center">
                    <Chip label={getChartName(row?.chart_type_id).name} color="primary" />
                  </TableCell>

                  <TableCell align="center">
                    {
                      row?.is_active
                        ? <Chip label="true" color="success" />
                        : <Chip label="false" color="error" />
                    }
                  </TableCell>

                  <TableCell align="center">
                    <Chip label={row?.ordering} color="primary" />
                  </TableCell>

                  <TableCell align="center">
                    <div className={styles.controlsWrapper}>
                      <Button
                        color="success"
                        margin="center"
                        variant="contained"
                        onClick={() => navigate(`/admin/edit-custom-chart/${row.id}`)}
                      >
                        Edit
                      </Button>

                      <Button
                        color="error"
                        margin="center"
                        variant="contained"
                        onClick={() => showModal(EditChartModal, { row }, false)}
                      >
                        Delete
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
