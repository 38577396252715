/* eslint-disable jsx-a11y/anchor-is-valid */
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import Layout from '../../components/Layout';
import { selectorsAuth } from '../../redux/slices/sliceAuth';
import { ModalContext } from '../../context/ModalContext';
import styles from './styles.module.scss';
import FormChangePassword from '../../components/forms/ChangePassword';

function Account() {
  const profileData = useSelector(selectorsAuth.getProfile);
  const { showModal } = useContext(ModalContext);
  return (
    <Layout>
      <>
        <Typography variant="h5">Account</Typography>
        <div className={styles.userInfo}>
          <TextField value={profileData?.name} name="name" disabled label="Name" />
          <TextField value={profileData?.email} name="email" disabled label="Email" />
        </div>
        <Typography variant="subtitle1">
          <a
            href=""
            onClick={(e) => {
              e.preventDefault();
              showModal(FormChangePassword, {});
            }}
          >
            Reset Password

          </a>
        </Typography>
      </>

    </Layout>

  );
}

Account.propTypes = {

};
export default Account;
