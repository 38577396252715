/* eslint-disable react/prop-types */
import React, { useCallback, useEffect } from 'react';
import { FieldArray, Form, Formik } from 'formik';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { toast } from 'react-toastify';
import { CircularProgress, MenuItem } from '@mui/material';
import { useSelector } from 'react-redux';
import actionsCustomCharts from '../../../redux/actions/customCharts';
import sliceCustomCharts from '../../../redux/slices/customCharts';
import { customChartsSelectors } from '../../../redux/selectors/customCharts';
import { dispatchAction } from '../../../redux/store';

import styles from './styles.module.scss';
import AutoCompletField from '../inputs/Autocomplete';
import { companiesSelectors } from '../../../redux/selectors/companies';
import actionsCompanies from '../../../redux/actions/companies';

function FormEditCustomProp({ selectedChart }) {
  const chartTypes = useSelector(customChartsSelectors.getChartTypes);
  const selectedCompany = useSelector(companiesSelectors.getSelectedCompany);

  const handleSubmit = useCallback(
    (values) => {
      actionsCustomCharts
        .updateCustomChart(selectedChart.id, values)
        .then(() => {
          toast.success('Edited successfully!');
        })
        .catch((error) => {
          if (error?.detail) {
            toast.error(error?.detail);
          }
          if (error?.non_field_errors) {
            toast.error(error?.non_field_errors?.join('\n'));
          }
        });
    },
    [selectedChart.id],
  );

  const initialValues = {
    title: selectedChart.title,
    asset_code: selectedChart.asset_code,
    chart_type_id: selectedChart.chart_type_id,
    units: selectedChart.units,
    x_axis: selectedChart.x_axis,
    y_axis: selectedChart.y_axis,
    ordering: selectedChart.ordering,
    is_active: selectedChart.is_active,
  };

  useEffect(() => () => {
    dispatchAction(sliceCustomCharts.actions.setSelectedCustomChart, null);
  }, []);

  useEffect(() => {
    actionsCompanies.getCompanyByCode(selectedChart?.asset_code);
  }, [selectedChart]);
  if (!selectedCompany || !selectedCompany?.code) {
    return <CircularProgress />;
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({
        values,
        handleChange,
        setFieldValue,
        touched,
        errors,
      }) => (
        <Form>
          <Typography
            variant="h4"
            align="center"
            marginBottom="20px"
          >
            Edit custom chart
          </Typography>
          <div className={styles.column}>
            <AutoCompletField
              onChange={setFieldValue}
              defaultValue={{ code: values.asset_code, name: selectedCompany?.name }}
              disabled={!!(selectedCompany)}
              error={touched.asset_code && Boolean(errors.asset_code)}
              helperText={touched.asset_code && errors.asset_code}
            />
            {/* <TextField
              label="Company"
              variant="outlined"
              name="asset_code"
              disabled
              defaultValue={values.asset_code}
              onChange={handleChange}
            /> */}
            <div className={styles.row}>
              <div className={styles.column}>
                <TextField
                  label="Chart name"
                  variant="outlined"
                  name="title"
                  defaultValue={values.title}
                  onChange={handleChange}
                />

                <TextField
                  label="Units"
                  variant="outlined"
                  name="units"
                  defaultValue={values.units}
                  onChange={handleChange}
                />
              </div>

              <div className={styles.column}>
                <TextField
                  label="Type"
                  variant="outlined"
                  name="chart_type_id"
                  select
                  defaultValue={values.chart_type_id}
                  onChange={handleChange}
                >
                  {chartTypes.map((element) => (
                    <MenuItem
                      key={element.id}
                      value={element.id}
                      selected={element.id === values.chart_type_id}
                    >
                      {element.name}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  type="number"
                  label="Ordering"
                  variant="outlined"
                  name="ordering"
                  defaultValue={values.ordering}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className={styles.row}>
              <FieldArray name="x_axis.data">
                {({
                  remove,
                  push,
                }) => (
                  <div className={styles.column}>
                    <Typography
                      variant="h4"
                      align="left"
                      marginBottom="20px"
                    >
                      X Axis
                    </Typography>

                    {values.x_axis.data.map((el, index) => (
                      <div className={styles.column}>
                        {values.x_axis.data.length > 1 && (
                          <Button
                            color="secondary"
                            type="button"
                            variant="outlined"
                            className={styles.controlButton}
                            onClick={() => remove(index)}
                          >
                            Delete
                          </Button>
                        )}
                        <TextField
                          label="Name"
                          name={`x_axis.data.${index}`}
                          variant="outlined"
                          defaultValue={el}
                          onChange={handleChange}
                        />
                      </div>
                    ))}
                    <Button
                      fullWidth
                      size="large"
                      type="button"
                      variant="contained"
                      color="success"
                      onClick={() => push()}
                    >
                      Add row
                    </Button>
                  </div>
                )}
              </FieldArray>

              <FieldArray name="y_axis">
                {({
                  remove,
                  push,
                }) => (
                  <div className={styles.column}>
                    <Typography
                      variant="h4"
                      align="left"
                      marginBottom="20px"
                    >
                      Datasets
                    </Typography>
                    <div className={styles.row}>
                      {values.y_axis.map((el, index) => (
                        <div className={styles.column}>
                          {values.y_axis.length > 1 && (
                            <Button
                              color="secondary"
                              type="button"
                              variant="outlined"
                              className={styles.controlButtonColumn}
                              onClick={() => remove(index)}
                            >
                              Delete
                            </Button>
                          )}
                          <div className={styles.column}>
                            <TextField
                              label="Label name"
                              name={`y_axis.${index}.label`}
                              variant="outlined"
                              defaultValue={el.label}
                              onChange={handleChange}
                            />
                            <TextField
                              label="Group"
                              name={`y_axis.${index}.group`}
                              variant="outlined"
                              defaultValue={el.group}
                              onChange={handleChange}
                            />
                            <FieldArray name={`y_axis.${index}.data`}>
                              <>
                                {values.x_axis.data.map((_, idx) => (
                                  <TextField
                                    label="Data"
                                    type="number"
                                    defaultValue={el.data[idx]}
                                    name={`y_axis.${index}.data.${idx}`}
                                    variant="outlined"
                                    onChange={handleChange}
                                  />
                                ))}
                              </>
                            </FieldArray>

                          </div>
                        </div>
                      ))}
                    </div>
                    <Button
                      fullWidth
                      size="large"
                      type="button"
                      variant="contained"
                      color="success"
                      onClick={() => push({
                        label: '',
                        group: '',
                        data: '',
                      })}
                    >
                      Add column
                    </Button>
                  </div>
                )}
              </FieldArray>
            </div>
          </div>

          <div className={styles.buttonWrapper}>
            <Button
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Edit
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default FormEditCustomProp;
