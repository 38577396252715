import React from 'react';

function PlusIcon() {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_0_226)">
        <path fillRule="evenodd" clipRule="evenodd" d="M27.5 21H23V16.5C23 16.2237 22.7762 16 22.5 16H21.5C21.2238 16 21 16.2237 21 16.5V21H16.5C16.2237 21 16 21.2238 16 21.5V22.5C16 22.7762 16.2237 23 16.5 23H21V27.5C21 27.7762 21.2238 28 21.5 28H22.5C22.7762 28 23 27.7762 23 27.5V23H27.5C27.7762 23 28 22.7762 28 22.5V21.5C28 21.2238 27.7762 21 27.5 21Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_0_226">
          <rect width="44" height="44" fill="white" />
        </clipPath>
      </defs>
    </svg>

  );
}

export default PlusIcon;
