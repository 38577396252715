import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Layout from '../../components/Layout';
import routes from '../../constants/routes';
import actionsAuth from '../../redux/actions/auth';

function VerifyEmail() {
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const navigate = useNavigate();
  const user = urlParams.get('user');
  const expired = urlParams.get('expired');
  const signature = urlParams.get('signature');

  useEffect(() => {
    if (!user || !expired || !signature) {
      toast.error('Link is invalid');
      return;
    }
    actionsAuth
      .verifyEmail({ user, expired, signature })
      .then(() => {
        toast.success('Email confirmed!');
        navigate(routes.HOME_PAGE);
      })
      .catch((error) => {
        toast.error(error?.detail || 'Something went wrong.');
        navigate(routes.HOME_PAGE);
      });
  }, [expired, signature, user, navigate]);
  return (
    <Layout>
      <div />
    </Layout>

  );
}

VerifyEmail.propTypes = {

};
export default VerifyEmail;
