import React from 'react';
import AdminLayout from '../../components/AdminLayout';
import FormCreateCustomProp from '../../components/forms/CreateCustomProp';

function CreateCustomPropCompany() {
  return (
    <AdminLayout>
      <FormCreateCustomProp />
    </AdminLayout>
  );
}

export default CreateCustomPropCompany;
