/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
// import cn from 'classnames';
// import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Pagination from '@mui/material/Pagination';
// import PropTypes from 'prop-types';
// import cn from 'classnames';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';
import formatNumber from '../../utils/number';
import { selectorsAsssets } from '../../redux/slices/sliceAssets';
import StackedBarChart, { LINE_CHARTS } from '../../components/charts/StackedBarChart';
import PERIODS from '../../constants/api';
import actionsAssets from '../../redux/actions/assets';

function Overview({ companyInfo, asset }) {
  const [period, setPeriod] = useState(PERIODS.WEEK.type);
  const companyNews = useSelector(selectorsAsssets.news);
  const priceCharts = useSelector(selectorsAsssets.price);
  const count = useSelector(selectorsAsssets.newsCount);
  useEffect(() => {
    actionsAssets.getPriceChart(asset, period);
  }, [asset, period]);

  const updateNewsList = useCallback((page) => {
    actionsAssets.getNews({ code: asset, page });
  }, []);
  console.log(companyInfo);
  return (
    <>
      <div className={cn(styles.filters, styles.periodFilters)}>
        {Object.values(PERIODS).map((item) => (
          <div
            className={cn({
              [styles.active]: period === item.type,
            })}
            onClick={() => setPeriod(item.type)}
          >
            {item.title}
          </div>
        ))}
      </div>
      <div className={styles.priceChartWrapper}>
        <StackedBarChart
          chartData={{ ...priceCharts[period], ...{ type: LINE_CHARTS } }}
          hideLegend
        />

      </div>
      <Divider className={styles.divider} />
      <div className={styles.overviewWrapper}>
        <Typography variant="subtitle1">Stats</Typography>
        <div className={styles.detailsWrapper}>
          <div className={styles.detailsItem}>
            <div className={styles.item}>
              <Typography className={styles.detailName} variant="subtitle2">Open</Typography>
              <Typography variant="subtitle2">{`${companyInfo?.price?.open === null ? 'No data' : `$ ${companyInfo?.price?.open}`}`}</Typography>
            </div>
            <div className={styles.item}>
              <Typography className={styles.detailName} variant="subtitle2">High</Typography>
              <Typography variant="subtitle2">{`${companyInfo?.price?.high === null ? 'No data' : `$ ${companyInfo?.price?.high}`}`}</Typography>
            </div>
            <div className={styles.item}>
              <Typography className={styles.detailName} variant="subtitle2">Low</Typography>
              <Typography variant="subtitle2">{`${companyInfo?.price?.low === null ? 'No data' : `$ ${companyInfo?.price?.low}`}`}</Typography>
            </div>
          </div>
          <div className={styles.detailsItem}>
            <div className={styles.item}>
              <Typography className={styles.detailName} variant="subtitle2">Mkt cap</Typography>
              <Typography variant="subtitle2">
                {`${companyInfo?.price?.market_cap === null ? 'No data' : `$ ${Intl.NumberFormat('en-GB', {
                  notation: 'compact',
                  minimumFractionDigits: 2,
                }).format(companyInfo?.price?.market_cap)}`}`}
              </Typography>
            </div>
            <div className={styles.item}>
              <Typography className={styles.detailName} variant="subtitle2">P/E ratio</Typography>
              <Typography variant="subtitle2">{`${companyInfo?.price?.pe_ratio === null ? 'No data' : `${formatNumber(companyInfo.pe_ratio) || 0}`}`}</Typography>
            </div>
            <div className={styles.item}>
              <Typography className={styles.detailName} variant="subtitle2">Div yield</Typography>
              <Typography variant="subtitle2">{`${companyInfo?.price?.dividend_yield === null ? 'No data' : `${companyInfo?.dividend_yield ? companyInfo.dividend_yield * 100 : 0} %`}`}</Typography>
            </div>
          </div>
          <div className={styles.detailsItem}>
            <div className={styles.item}>
              <Typography className={styles.detailName} variant="subtitle2">CDP score</Typography>
              <Typography variant="subtitle2">No Data</Typography>
            </div>
            <div className={styles.item}>
              <Typography className={styles.detailName} variant="subtitle2">52-wk high</Typography>
              <Typography variant="subtitle2">{`${companyInfo?.wk52_high === null ? 'No data' : `$ ${formatNumber(companyInfo?.wk52_high) || 0}`}`}</Typography>
            </div>
            <div className={styles.item}>
              <Typography className={styles.detailName} variant="subtitle2">52-wk low</Typography>
              <Typography variant="subtitle2">{`${companyInfo?.wk52_low === null ? 'No data' : `$ ${formatNumber(companyInfo?.wk52_low) || 0}`}`}</Typography>
            </div>
          </div>
        </div>
      </div>
      <Divider className={styles.divider} />
      <div className={styles.companyInfo}>
        <Typography variant="subtitle1">About</Typography>
        <Typography variant="body2">{companyInfo?.description}</Typography>
      </div>
      <Divider className={styles.divider} />
      <div className={styles.newsWrapper}>
        <Typography variant="subtitle1">News</Typography>
        {companyNews.map((item) => (
          <div className={styles.newsItem}>
            <a href={item.url} target="_blank" rel="noreferrer">
              <Typography className={styles.title} variant="subtitle1">{item.title}</Typography>
              <Typography className={styles.date} variant="body2">{item.published}</Typography>
              <Typography className={styles.descriptionText} variant="subtitle2">{item.content}</Typography>
            </a>
          </div>
        ))}
        {companyNews.length > 0 && (
        <div className={styles.pagination}>
          <Pagination onChange={(event, page) => updateNewsList(page)} count={count} variant="outlined" shape="rounded" />
        </div>
        )}
      </div>

      {/*
        <Box key="test2" padding={3}>
          <StackedBarChart type="line" />
        </Box>
        <Box key="test3" padding={3}>
          <StackedBarChart type="bar" />
        </Box> */}
    </>

  );
}

Overview.propTypes = {

};
export default Overview;
