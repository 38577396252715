/* eslint-disable max-len */
import Api from '../../utils/Api';
import sliceAssets from '../slices/sliceAssets';
import apiURL from '../../constants/apiURL';
import { dispatchAction } from '../store';
import PERIODS, { SORTING } from '../../constants/api';

const actionsAssets = {};

actionsAssets.getList = ({ query = '', size = 20, orderBy = SORTING.MARKET, isDesc = true }) => Api.get({
  url: apiURL.getAssets({ page: 1, size, query, orderBy, isDesc }),
  action: sliceAssets.actions.setList,
});

actionsAssets.getFullList = ({
  page = 1,
  size = 20,
  query = '',
  orderBy = SORTING.MARKET,
  isDesc = true,
}) => Api.get({
  url: apiURL.getAssets({ page, size, query, orderBy, isDesc }),
  action: sliceAssets.actions.setFullList,
  withHeaders: true,
}).then((response) => {
  dispatchAction(sliceAssets.actions.setCount, Number(response.headers['x-total-pages']));
  dispatchAction(sliceAssets.actions.setSelectedPage, Number(page));
});

actionsAssets.getDetails = (code) => Api.get({
  url: apiURL.getAssetDetails(code),
  action: sliceAssets.actions.setDetails,
}).then((response) => {
  if (response?.charts?.length > 0) {
    response?.charts.map((chart) => {
      const data = chart.id;
      const type = chart?.chart_type?.id;
      const title = chart?.title;
      const unit = chart?.y_axis;
      return actionsAssets.getChartData(code, data, type, title, unit, false);
    });
  }
  dispatchAction(sliceAssets.actions.resetCustomCharts);
  actionsAssets.getNews({ code });
  actionsAssets.getPriceChart(code, PERIODS.WEEK.type);
  if (response?.custom_charts?.length > 0) {
    response.custom_charts.map((item) => {
      const data = item.id;
      const type = item?.chart_type?.id;
      const title = item?.title;
      const unit = item?.y_axis;
      return actionsAssets.getChartData(code, data, type, title, unit, true);
    });
  }
});

actionsAssets.getChartData = (code, id, type, title, unit, isCustom = false) => Api.get({
  url: apiURL.getChartData(code, id),
  // action: sliceAssets.actions.setCharts,
}).then((response) => {
  if (isCustom) {
    dispatchAction(sliceAssets.actions.setCustomCharts, { ...response, ...{ chartId: id, title, type, unit } });
  } else {
    dispatchAction(sliceAssets.actions.setCharts, { ...response, ...{ chartId: id, title, type, unit } });
  }
});

actionsAssets.getNews = ({ code, page = 1,
  size = 5 }) => Api.get({
  url: apiURL.getNews({ code, page, size }),
  action: sliceAssets.actions.setNews,
  withHeaders: true,
}).then((response) => {
  dispatchAction(sliceAssets.actions.setNewsCount, Number(response.headers['x-total-pages']));
  dispatchAction(sliceAssets.actions.setNewsSelectedPage, Number(page));
});

actionsAssets.getPriceChart = (code, period) => Api.get({
  url: apiURL.getPriceChart(code, period),
}).then((response) => {
  dispatchAction(sliceAssets.actions.setPriceChart, { ...response, ...{ period } });
});

actionsAssets.searchAssets = (query) => Api.get({
  url: apiURL.autocompleteSearch(query),
  action: sliceAssets.actions.setAutocomplete,
});

actionsAssets.downloadFile = ({ code, id, fileName, period }) => Api.get({
  url: apiURL.downloadFile(code, id, period),
  responseType: 'blob',
}).then((response) => {
  const href = URL.createObjectURL(response);
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', `${fileName}.xlsx`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
});

actionsAssets.getCompareData = (code) => Api.get({
  url: apiURL.getAssetDetails(code),
  // action: sliceAssets.actions.setDetails,
}).then(async (response) => {
  if (response?.charts?.length > 0) {
    dispatchAction(sliceAssets.actions.prepareCompareChartData, { code });
    dispatchAction(sliceAssets.actions.setIsLoading);
    const promiseRequests = [];
    response?.charts.map(async (chart) => {
      const data = chart.id;
      const type = chart?.chart_type?.id;
      const title = chart?.title;
      const unit = chart?.y_axis;

      promiseRequests.push(actionsAssets.getCompareChartData(code, data, type, title, unit, false));
    });
    // eslint-disable-next-line no-unused-vars
    const responses = await Promise.all(promiseRequests);
    dispatchAction(sliceAssets.actions.stopLoading);
  }
});
actionsAssets.getCompareChartData = (code, chartId, type, title, unit) => Api.get({
  url: apiURL.getChartData(code, chartId),
  // action: sliceAssets.actions.setCharts,
}).then((response) => {
  dispatchAction(sliceAssets.actions.setCompareChartsData, { ...response, ...{ type, title, unit, code, chartId } });
});

export default actionsAssets;
