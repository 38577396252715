/* eslint-disable comma-dangle */
/* eslint-disable operator-linebreak */
/* eslint-disable semi */
/* eslint-disable quotes */
import { nanoid } from '@reduxjs/toolkit';
import sliceAuth from '../redux/slices/sliceAuth';
import { dispatchAction } from '../redux/store';
import apiService, { HTTP_CODE, removeAuthHeader } from './apiService';
import sliceAdminAuth from '../redux/slices/sliceAdminAuth';

export const STATUS_REQUEST = {
  PENDING: 'pending',
  FULFILLED: 'fulfilled',
  REJECTED: 'rejected',
};

function wait(ms = 100) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

const setRequestStatus = (reqId, status, nameRequestStatus) => {
  if (!nameRequestStatus) {
    return;
  }
  dispatchAction(`loading/${nameRequestStatus}`, {
    reqId,
    nameRequestStatus,
    status,
  });
};

const requestWrapper = async ({
  url,
  method,
  requestData = null,
  options = {},
  nameRequestStatus,
  responseType
}) => {
  const reqId = nanoid();
  setRequestStatus(reqId, STATUS_REQUEST.PENDING, nameRequestStatus);
  await wait();
  return apiService({
    url,
    method,
    data: requestData,
    ...responseType && { responseType },
    ...options,
  })
    .then((response) => {
      setRequestStatus(reqId, STATUS_REQUEST.FULFILLED, nameRequestStatus);
      return response;
    })
    .catch((error) => {
      setRequestStatus(reqId, STATUS_REQUEST.REJECTED, nameRequestStatus);
      if (
        (error?.response?.status === HTTP_CODE.HTTP_403_FORBIDDEN &&
          (error?.response?.data.detail ===
            'CSRF Failed: CSRF token missing or incorrect.' ||
            error?.response?.data.detail ===
            'Authentication credentials were not provided.')) ||
        error?.response?.status === HTTP_CODE.HTTP_401_UNAUTHORIZED
      ) {
        removeAuthHeader();
        dispatchAction(sliceAuth.actions.signOut);
        dispatchAction(sliceAdminAuth.actions.signOut);
      }
      throw error?.response?.data || {};
    });
};

const processResponse = async (
  response,
  {
    addDataToResponse,
    requestData,
    action,
    withHeaders
  },
) => {
  const responseData = response?.data;

  if (addDataToResponse) {
    // eslint-disable-next-line no-underscore-dangle
    responseData._requestData = requestData;
  }

  if (action) {
    dispatchAction(action, responseData);
  }

  if (withHeaders) {
    return {
      data: responseData,
      headers: response.headers,
    };
  }

  return responseData;
};

const Api = {};

Api.get = async (params) => {
  const promiseResponse = requestWrapper({
    ...params,
    method: 'get'
  });
  return promiseResponse.then((response) => processResponse(response, params));
};

Api.post = (params) => {
  const promiseResponse = requestWrapper({
    ...params,
    method: 'post'
  });
  return promiseResponse.then((response) => processResponse(response, params));
};

Api.put = async (params) => {
  const promiseResponse = requestWrapper({
    ...params,
    method: 'put'
  });
  return promiseResponse.then((response) => processResponse(response, params));
};

Api.patch = async (params) => {
  const promiseResponse = requestWrapper({
    ...params,
    method: 'patch'
  });
  return promiseResponse.then((response) => processResponse(response, params));
};

Api.delete = async (params) => {
  const promiseResponse = requestWrapper({
    ...params,
    method: 'delete'
  });
  return promiseResponse.then((response) => processResponse(response, params));
};

export default Api;
