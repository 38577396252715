/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
// import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import sliceAssets, { selectorsAsssets } from '../../../redux/slices/sliceAssets';
import actionsAssets from '../../../redux/actions/assets';
import { dispatchAction } from '../../../redux/store';

// eslint-disable-next-line react/prop-types
// eslint-disable-next-line no-unused-vars
function AutoCompletField({ onChange, size, disabled, defaultValue, error, helperText }) {
  const [open, setOpen] = React.useState(false);
  const [values, setValues] = React.useState(defaultValue);
  const [isLoading, setIsloading] = useState(false);
  const searchResults = useSelector(selectorsAsssets.autocompleteResults);

  // const changeHandler = (event, value, reason) => {
  //   if (reason === 'select-option') {
  //     input.onChange(value);
  //   }
  // };

  const onCloseHandler = () => {
    setOpen(false);
    dispatchAction(sliceAssets.actions.resetSearch);
  };

  const inputChangeHandler = async (event, value, reason) => {
    if (reason === 'input') {
      setIsloading(true);
      await actionsAssets.searchAssets(value);
      setIsloading(false);
    }
  };

  return (
    <Autocomplete
      id="assest_autocomplete"
      style={{ width: '100%' }}
      open={open}
      disabled={disabled}
      noOptionsText="Start typing"
      onOpen={() => {
        setOpen(true);
      }}
      blurOnSelect
      onClose={onCloseHandler}
      isOptionEqualToValue={(option, value) => option.code === value.code}
      getOptionLabel={(option) => `${option?.code}: ${option?.name}`}
      options={searchResults}
      loading={isLoading}
      onChange={(event, value) => onChange('asset_code', value.code)}
      clearOnEscape
      value={values}
      onInputChange={inputChangeHandler}
      renderInput={(params) => (
        <TextField
          {...params}
          size={size}
          name="asset_code"
          disabled={disabled}
          label=""
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

export default AutoCompletField;
