import { createSelector } from '@reduxjs/toolkit';

const adminAuthState = (state) => state.authAdmin;

const getIsAuth = createSelector(
  adminAuthState,
  (state) => state.isAuthorized,
);

// eslint-disable-next-line import/prefer-default-export
export const adminAuthSelectors = {
  getIsAuth,
};
