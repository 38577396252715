import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  companies: [],
  count: 0,
  selectedPage: 1,
  selectedCompany: null,
};

const sliceCompanies = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    setCompanies(state, action) {
      state.companies = action.payload;
    },
    setCount(state, action) {
      state.count = action.payload;
    },
    setSelectedPage(state, action) {
      state.selectedPage = action.payload;
    },
    setSelectedCompany(state, action) {
      state.selectedCompany = action.payload;
    },
  },
});

export const reducerCompanies = sliceCompanies.reducer;
export default sliceCompanies;
