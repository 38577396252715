import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  users: [],
  count: 0,
  selectedPage: 1,
};

const sliceUsers = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsers(state, action) {
      state.users = action.payload;
    },
    setCount(state, action) {
      state.count = action.payload;
    },
    setSelectedPage(state, action) {
      state.selectedPage = action.payload;
    },
  },
});

export const reducerUsers = sliceUsers.reducer;
export default sliceUsers;
