import React from 'react';

function DownloadChartIcon() {
  return (
    <svg width="52" height="34" viewBox="0 0 52 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="52" height="34" rx="6" fill="#282829" />
      <path fillRule="evenodd" clipRule="evenodd" d="M30.2793 16.3465L26.2324 20.4043C26.1039 20.5328 25.8961 20.5328 25.7676 20.4043L21.7207 16.3465C21.5922 16.218 21.5922 16.0102 21.7207 15.8816L22.2566 15.3457C22.3879 15.2145 22.5984 15.2172 22.7242 15.3512L25.2863 18.0062V11.2031C25.2863 11.0227 25.434 10.875 25.6145 10.875H26.3801C26.5605 10.875 26.7082 11.0227 26.7082 11.2031V18.0062L29.2703 15.3512C29.3988 15.2199 29.6094 15.2172 29.7379 15.3457L30.2738 15.8816C30.4078 16.0102 30.4078 16.218 30.2793 16.3465V16.3465ZM30.9219 21.7031H21.0781C20.8977 21.7031 20.75 21.8508 20.75 22.0312V22.7969C20.75 22.9773 20.8977 23.125 21.0781 23.125H30.9219C31.1023 23.125 31.25 22.9773 31.25 22.7969V22.0312C31.25 21.8508 31.1023 21.7031 30.9219 21.7031Z" fill="white" />
    </svg>

  );
}

export default DownloadChartIcon;
