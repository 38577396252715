/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
// import Box from '@mui/material/Box';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Layout from '../../components/Layout';
import { selectorsAsssets } from '../../redux/slices/sliceAssets';
import actionsAssets from '../../redux/actions/assets';
import GreenArrow from '../../components/icons/GreenArrow';
import RedArrow from '../../components/icons/RedArrow';
// import PropTypes from 'prop-types';
// import cn from 'classnames';
import styles from './styles.module.scss';
import formatNumber from '../../utils/number';
import Financials from './Finacials';
import Overview from './Overview';

function CompanyPage() {
  const companyInfo = useSelector(selectorsAsssets.details);
  const chartData = useSelector(selectorsAsssets.charts);
  const customCharts = useSelector(selectorsAsssets.customCharts);
  const [page, setPage] = useState('financials');
  const { asset } = useParams();
  useEffect(() => {
    actionsAssets.getDetails(asset);
  }, [asset]);

  const onTabClick = (tab) => setPage(tab);

  // const data = useMemo(() => chartData, [chartData]);

  return (
    <Layout>
      <>
        <div className={styles.infoWrapper}>
          <div className={styles.infoItem}>
            <Typography variant="h4">{companyInfo?.name || ''}</Typography>
            <Typography variant="subtitle1">{companyInfo?.exchange?.code && `${companyInfo?.exchange?.code}: ${companyInfo?.code}`}</Typography>
          </div>
          <div className={styles.infoItemMiddle}>
            <Typography variant="h4">
              {companyInfo?.price?.market_cap && `$ ${Intl.NumberFormat('en-GB', {
                notation: 'compact',
              }).format(companyInfo?.price?.market_cap)}`}

            </Typography>
            <Typography variant="subtitle1">Market Cap</Typography>
          </div>
          <div className={styles.infoItemLast}>
            <Typography variant="h4">{companyInfo?.price?.close && `$ ${formatNumber(companyInfo?.price?.close)}`}</Typography>
            <Typography variant="subtitle1">
              {companyInfo?.price?.close && `${companyInfo?.price?.change > 0 ? '+' : ''}${formatNumber(companyInfo?.price?.change)} (${formatNumber(companyInfo?.price?.change_percent)
              } %)`}
              {' '}
              {companyInfo?.price?.change > 0 ? <GreenArrow /> : <RedArrow />}
              {' '}
              change

            </Typography>
          </div>
        </div>
        <Divider className={styles.divider} />
        <div className={styles.pageTabs}>
          <Button
            className={cn({
              [styles.active]: page === 'financials',
            })}
            onClick={() => onTabClick('financials')}
          >
            Financials

          </Button>
          <Button
            className={cn({
              [styles.active]: page === 'overview',
            })}
            onClick={() => onTabClick('overview')}
          >
            Overview

          </Button>
        </div>
        <Divider className={styles.divider} />
        {page === 'financials' && <Financials asset={asset} companyInfo={companyInfo} chartData={chartData} customCharts={customCharts} />}
        {page === 'overview' && <Overview companyInfo={companyInfo} asset={asset} />}

        {/*
        <Box key="test2" padding={3}>
          <StackedBarChart type="line" />
        </Box>
        <Box key="test3" padding={3}>
          <StackedBarChart type="bar" />
        </Box> */}

      </>

    </Layout>

  );
}

CompanyPage.propTypes = {

};
export default CompanyPage;
