/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useCallback, useContext, useState } from 'react';
import { useFormik } from 'formik';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import authSchema from '../validations/authSchema';
import useValidation from '../validations/useValidation';
import styles from './styles.module.scss';

// import styles from './styles.module.scss';
import actionsAuth from '../../../redux/actions/auth';
import { ModalContext } from '../../../context/ModalContext';
import FormSignUp from '../SignUp';
// import routes from '../../../constants/routes';

function FormEmailVerification({ email }) {
  const [loading, setLoading] = useState(false);
  const { showModal } = useContext(ModalContext);
  const [resendTimer, setResendTimer] = useState(false);
  // const [commonError, setCommonError] = useState('');

  const { scheme } = useValidation({
    scheme: authSchema,
    requiredFields: {
      email: true,
      password: true,
    },
  });

  const handleSubmit = useCallback(
    () => {
      setLoading(true);
      actionsAuth
        .resendEmail(email)
        .then(() => {
          setResendTimer(true);
          setLoading(false);
          setTimeout(() => setResendTimer(false), 30000);
        })
        .catch((error) => {
          setLoading(false);
          if (error?.detail) {
            toast.error(error?.detail);
          } else {
            toast.error('Something went wrong.');
          }
        });
    },
    [email],
  );

  return (
    <div>
      <Typography variant="h4">
        Verify your email address
      </Typography>
      <Typography variant="caption" className={styles.caption}>
        An email has been sent to your email address, cornell.b@gmail.com. Please click
        the link to verify your email address.
      </Typography>
      <div />
      {resendTimer && (
      <Typography variant="caption" color="error" className={styles.caption}>
        You can try resend after 30 seconds
      </Typography>
      )}
      <div className={styles.buttonWrapper}>
        <Button disabled={loading || resendTimer} size="large" onClick={() => handleSubmit()} fullWidth type="action" variant="contained">Resend Email</Button>
      </div>

    </div>
  );
}

export default FormEmailVerification;
