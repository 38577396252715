import React from 'react';
import SimpleLayout from '../../components/SimpleLayout';
import FormAdminSignIn from '../../components/forms/AdminSignIn';

function SignInAdminPage() {
  return (
    <SimpleLayout>
      <FormAdminSignIn />
    </SimpleLayout>
  );
}

export default SignInAdminPage;
