import React from 'react';

function RedArrow() {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ transform: 'rotate(180deg)' }}>
      <path fillRule="evenodd" clipRule="evenodd" d="M0.892936 6.13825L1.35697 6.60229C1.4668 6.71211 1.64488 6.71211 1.75473 6.60229L5.39057 2.96643V10.9687C5.39057 11.1241 5.5165 11.25 5.67182 11.25H6.32807C6.48339 11.25 6.60932 11.1241 6.60932 10.9687V2.96643L10.2452 6.60229C10.355 6.71211 10.5331 6.71211 10.6429 6.60229L11.107 6.13825C11.2168 6.02842 11.2168 5.85034 11.107 5.74049L6.19881 0.832371C6.08898 0.722543 5.9109 0.722543 5.80105 0.832371L0.892936 5.74051C0.783084 5.85034 0.783084 6.02842 0.892936 6.13825Z" fill="#FF3B3B" />
    </svg>

  );
}

export default RedArrow;
