import React, { useCallback } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { toast } from 'react-toastify';
import authSchema from '../validations/authSchema';
import useValidation from '../validations/useValidation';
import actionsAdminAuth from '../../../redux/actions/adminAuth';
import adminRoutes from '../../../constants/adminRoutes';

import styles from './styles.module.scss';

function FormAdminSignIn() {
  const navigate = useNavigate();

  const { scheme } = useValidation({
    scheme: authSchema,
    requiredFields: {
      email: true,
      password: true,
    },
  });

  const handleSubmit = useCallback(
    (values, { setFieldError }) => {
      actionsAdminAuth
        .signIn({ ...values, remember: true })
        .then(() => {
          navigate(adminRoutes.USERS);
          toast.success('Login successful');
        })
        .catch((error) => {
          if (error?.detail) {
            toast.error(error?.detail);
          }
          if (error?.non_field_errors) {
            toast.error(error?.non_field_errors?.join('\n'));
          }
          if (error?.password) {
            setFieldError('password', error?.password?.join('\n'));
          }
          if (error?.email) {
            setFieldError('email', error?.email?.join('\n'));
          }
        });
    },
    [navigate],
  );
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: scheme,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Typography
        variant="h4"
        align="center"
      >
        Admin
      </Typography>
      <div className={styles.inputWrapper}>
        <TextField
          label="Email"
          variant="outlined"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />
      </div>
      <div className={styles.buttonWrapper}>
        <Button
          fullWidth
          size="large"
          type="submit"
          variant="contained"
        >
          Log In
        </Button>
      </div>
    </form>
  );
}

export default FormAdminSignIn;
