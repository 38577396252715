/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useCallback, useContext, useState } from 'react';
import { useFormik } from 'formik';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import authSchema from '../validations/authSchema';
import useValidation from '../validations/useValidation';
import styles from './styles.module.scss';

// import styles from './styles.module.scss';
import actionsAuth from '../../../redux/actions/auth';
import { ModalContext } from '../../../context/ModalContext';
import FormSignUp from '../SignUp';
import FormSignIn from '../SignIn';
// import routes from '../../../constants/routes';
const RECOVERY_STATUSES = {
  PENDING: 'pending',
  SUCCESS: 'success',
  FAIL: 'fail',
};

function FormForgotPassword() {
  const [loading, setLoading] = useState(false);
  const { showModal, hideModal } = useContext(ModalContext);
  const [status, setStatus] = useState(RECOVERY_STATUSES.PENDING);
  const [resendTimer, setResendTimer] = useState(false);
  // const [commonError, setCommonError] = useState('');

  const { scheme } = useValidation({
    scheme: authSchema,
    requiredFields: {
      email: true,
    },
  });

  const handleSubmit = useCallback(
    (values, { setFieldError }) => {
      setLoading(true);
      setStatus(RECOVERY_STATUSES.PENDING);
      actionsAuth
        .forgotPassword({ ...values })
        .then(() => {
          setStatus(RECOVERY_STATUSES.SUCCESS);
          setResendTimer(true);
          setTimeout(() => setResendTimer(false), 30000);
          toast.success('Email new temporarly password was sent to provided email');
        })
        .catch((error) => {
          setStatus(RECOVERY_STATUSES.FAIL);
          toast.error('Account with provided email was not found');
        }).finally(() => {
          setLoading(false);
        });
    },
    [],
  );

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: scheme,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Typography variant="h4">
        Forgot Password?
      </Typography>
      <Typography variant="caption" className={styles.caption}>
        Please enter email for your account.
      </Typography>
      <div className={styles.inputWrapper}>
        <TextField
          label="Email"
          variant="outlined"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
      </div>
      <div className={styles.infoMessage}>
        {status === RECOVERY_STATUSES.SUCCESS && (
        <>
          <Typography variant="caption">
            Please check your email for letter with temporarly password. You can
            {' '}
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                showModal(FormSignIn, {});
              }}
            >
              Log In

            </a>
            {' '}
            using this new password.
          </Typography>
          <Typography variant="caption" color="error" className={styles.caption}>
            You can try resend after 30 seconds
          </Typography>
        </>
        )}
      </div>
      <div className={styles.buttonWrapper}>
        <Button disabled={resendTimer} size="large" fullWidth type="submit" variant="contained">Send</Button>
      </div>
      <div className={styles.infoText}>
        <Typography variant="caption">
          Don’t have an account?
          {' '}
          <a
            href=""
            onClick={(e) => {
              e.preventDefault();
              showModal(FormSignUp, {});
            }}
          >
            Sign Up

          </a>
        </Typography>
      </div>

    </form>
  );
}

export default FormForgotPassword;
