import { SORTING } from '../../constants/api';

export const data = [
  {
    title: 'Apple',
    ticker: 'AAPL',
    price: '$ 159.55',
    change: -1.13,
    market: '$ 2.56T',
  },
  {
    title: 'Alphabet',
    ticker: 'GOOGL',
    price: '$ 109.01',
    change: -0.38,
    market: '$ 1.43T',
  },
  {
    title: 'Amazon',
    ticker: 'AMZN',
    price: '$ 128.25',
    change: -1.10,
    market: '$ 1.30T',
  },
  {
    title: 'Tesla',
    ticker: 'TSLA',
    price: '$ 281.25',
    change: -1.01,
    market: '$ 883.5B',
  },
  {
    title: 'Meta Platforms Inc',
    ticker: 'FB',
    price: '$ 196.64',
    change: -0.51,
    market: '$ 561.2B',
  },
  {
    title: 'Apple',
    ticker: 'AAPL',
    price: '$ 159.55',
    change: -1.13,
    market: '$ 2.56T',
  },
  {
    title: 'Alphabet',
    ticker: 'GOOGL',
    price: '$ 109.01',
    change: -0.38,
    market: '$ 1.43T',
  },
  {
    title: 'Amazon',
    ticker: 'AMZN',
    price: '$ 128.25',
    change: -1.10,
    market: '$ 1.30T',
  },
  {
    title: 'Tesla',
    ticker: 'TSLA',
    price: '$ 281.25',
    change: -1.01,
    market: '$ 883.5B',
  },
  {
    title: 'Meta Platforms Inc',
    ticker: 'FB',
    price: '$ 196.64',
    change: -0.51,
    market: '$ 561.2B',
  },
  {
    title: 'Apple',
    ticker: 'AAPL',
    price: '$ 159.55',
    change: -1.13,
    market: '$ 2.56T',
  },
  {
    title: 'Alphabet',
    ticker: 'GOOGL',
    price: '$ 109.01',
    change: 0.38,
    market: '$ 1.43T',
  },
  {
    title: 'Amazon',
    ticker: 'AMZN',
    price: '$ 128.25',
    change: -1.10,
    market: '$ 1.30T',
  },
  {
    title: 'Tesla',
    ticker: 'TSLA',
    price: '$ 281.25',
    change: -1.01,
    market: '$ 883.5B',
  },
  {
    title: 'Meta Platforms Inc',
    ticker: 'FB',
    price: '$ 196.64',
    change: 0.51,
    market: '$ 561.2B',
  },
  {
    title: 'Apple',
    ticker: 'AAPL',
    price: '$ 159.55',
    change: -1.13,
    market: '$ 2.56T',
  },
  {
    title: 'Alphabet',
    ticker: 'GOOGL',
    price: '$ 109.01',
    change: -0.38,
    market: '$ 1.43T',
  },
  {
    title: 'Amazon',
    ticker: 'AMZN',
    price: '$ 128.25',
    change: -1.10,
    market: '$ 1.30T',
  },
  {
    title: 'Tesla',
    ticker: 'TSLA',
    price: '$ 281.25',
    change: 1.01,
    market: '$ 883.5B',
  },
  {
    title: 'Meta Platforms Inc',
    ticker: 'FB',
    price: '$ 196.64',
    change: -0.51,
    market: '$ 561.2B',
  },
];

export const columns = [
  {
    id: 'favorite',
    name: '',
    sort: false,
    numeric: false,
    disablePadding: true,
  },
  {
    id: 'title',
    name: 'Stock',
    sort: true,
    numeric: false,
    disablePadding: true,
    orderBy: SORTING.NAME,
  },
  {
    id: 'ticker',
    name: 'Ticker',
    sort: true,
    numeric: false,
    disablePadding: true,
    orderBy: SORTING.CODE,
  },
  {
    id: 'price',
    name: 'Price',
    sort: true,
    numeric: true,
    disablePadding: true,
    orderBy: SORTING.CLOSE,
  },
  {
    id: 'change',
    name: '+ / -',
    sort: true,
    numeric: true,
    disablePadding: true,
    orderBy: SORTING.CHANGE,
  },
  {
    id: 'market',
    name: 'MARKET CAP',
    sort: true,
    numeric: true,
    disablePadding: true,
    orderBy: SORTING.MARKET,
  },

];
