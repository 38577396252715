import React from 'react';
import routes from '../../constants/routes';
import BurgerIcon from '../icons/BurgerIcon';
import PersonIcon from '../icons/PersonIcon';

const menuItemsMain = [
  {
    title: 'All Companies',
    icon: <BurgerIcon />,
    url: routes.COMPANIES_PAGE,
  },
  {
    title: 'Compare',
    icon: <BurgerIcon />,
    url: routes.COMPARE_PAGE,
  },
  {
    title: 'Calendar',
    icon: <BurgerIcon />,
    url: routes.CALENDAR_PAGE,
  },
  {
    title: 'Account',
    icon: <PersonIcon />,
    url: routes.ACCOUNT,
    onlyAuth: true,
  },
  {
    title: 'News',
    icon: <PersonIcon />,
    url: routes.NEWS_PAGE,
    onlyAuth: true,
  },
];
export default menuItemsMain;
