/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from 'react';
// import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import Layout from '../../components/Layout';
import styles from './styles.module.scss';
import actionsProfile from '../../redux/actions/profile';
import { selectorsProfile } from '../../redux/slices/sliceProfile';

function NewsPage() {
  const news = useSelector(selectorsProfile.news);
  // const page = useSelector(selectorsProfile.newsPage);
  const count = useSelector(selectorsProfile.newsCount);

  useEffect(() => {
    actionsProfile.getFavoriteNews();
  }, []);

  const updateNewsList = useCallback((page) => {
    actionsProfile.getFavoriteNews(page);
  }, []);

  // const data = useMemo(() => chartData, [chartData]);

  return (
    <Layout>
      <div className={styles.newsWrapper}>
        <Typography variant="h5">News</Typography>
        {news.length === 0 && (
          <Typography variant="subtitle1">No news available</Typography>
        )}
        {news.map((item) => (
          <div className={styles.newsItem}>
            <a href={item.url} target="_blank" rel="noreferrer">
              <Typography className={styles.title} variant="subtitle1">{item.title}</Typography>
              <Typography className={styles.date} variant="body2">{item.published}</Typography>
              <Typography className={styles.descriptionText} variant="subtitle2">{item.content}</Typography>
            </a>
          </div>
        ))}
        {news && news.length > 0 && (
        <div className={styles.pagination}>
          <Pagination onChange={(event, page) => updateNewsList(page)} count={count} variant="outlined" shape="rounded" />
        </div>
        )}

      </div>
    </Layout>

  );
}

NewsPage.propTypes = {

};
export default NewsPage;
