/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useCallback, useContext, useState } from 'react';
import { useFormik } from 'formik';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import authSchema, { signInSchema } from '../validations/authSchema';
import useValidation from '../validations/useValidation';
import styles from './styles.module.scss';

// import styles from './styles.module.scss';
import actionsAuth from '../../../redux/actions/auth';
import { ModalContext } from '../../../context/ModalContext';
import FormSignUp from '../SignUp';
import FormForgotPassword from '../ForgotPassword';
// import routes from '../../../constants/routes';

function FormSignIn() {
  const [loading, setLoading] = useState(false);
  const { showModal, hideModal } = useContext(ModalContext);
  // const [commonError, setCommonError] = useState('');

  const { scheme } = useValidation({
    scheme: signInSchema,
    requiredFields: {
      email: true,
      password: true,
    },
  });

  const handleSubmit = useCallback(
    (values, { setFieldError }) => {
      // setCommonError();
      setLoading(true);
      actionsAuth
        .signIn({ ...values, remember: true })
        .then(() => {
          // toast.success('Login successful');
          hideModal();
          toast.success('Successfully login');
        })
        .catch((error) => {
          setLoading(false);
          if (error?.detail) {
            toast.error(error?.detail);
          }
          if (error?.non_field_errors) {
            toast.error(error?.non_field_errors?.join('\n'));
            // setCommonError(error?.non_field_errors?.join('\n'));
          }
          if (error?.password) {
            setFieldError('password', error?.password?.join('\n'));
          }
          if (error?.email) {
            setFieldError('email', error?.email?.join('\n'));
          }
        });
    },
    [hideModal],
  );

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: scheme,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Typography variant="h4">
        Log in
      </Typography>
      <Typography variant="caption" className={styles.caption}>
        Welcome back
      </Typography>
      <div className={styles.inputWrapper}>
        <TextField
          label="Email"
          variant="outlined"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />
      </div>
      <div className={styles.forgotPassword}>
        <Typography variant="caption">
          <a
            href=""
            onClick={(e) => {
              e.preventDefault();
              showModal(FormForgotPassword, {});
            }}
          >
            Forgot Password?

          </a>
        </Typography>
      </div>
      <div className={styles.buttonWrapper}>
        <Button size="large" fullWidth type="submit" variant="contained">Log In</Button>
      </div>
      <div className={styles.infoText}>
        <Typography variant="caption">
          Don’t have an account?
          {' '}
          <a
            href=""
            onClick={(e) => {
              e.preventDefault();
              showModal(FormSignUp, {});
            }}
          >
            Sign Up

          </a>
        </Typography>
      </div>

    </form>
  );
}

export default FormSignIn;
