const PERIODS = {
  WEEK: { type: 'week', title: '1W' },
  MONTH: { type: 'month', title: '1M' },
  QUARTER: { type: 'quarter', title: '3M' },
  YEAR: { type: 'year', title: '1Y' },
  FIVE_YEAR: { type: '5year', title: '5Y' },
  ALL_TIME: { type: 'all_time', title: 'All Time' },
};

export const SORTING = {
  CODE: 'code',
  NAME: 'name',
  CLOSE: 'close',
  MARKET: 'market_cap',
  CHANGE: 'change_percent',
};

export default PERIODS;
