/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
import React, { useCallback, useMemo, useState } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';
import { useSelector } from 'react-redux';
import Layout from '../../components/Layout';
// import PropTypes from 'prop-types';
// import cn from 'classnames';
import styles from './styles.module.scss';
import AutoCompleteAssetsField from '../../components/Autocomplete';
import actionsAssets from '../../redux/actions/assets';
import sliceAssets, { selectorsAsssets } from '../../redux/slices/sliceAssets';
import { dispatchAction } from '../../redux/store';
import StackedBarChart from '../../components/charts/StackedBarChart';

const compareItems = [
  { type: 'revenue',
    chartId: 1,
    name: 'Revenue',
    groupedData: false,
  },
  { type: 'gross_profit',
    name: 'Gross Profit',
    chartId: 1,
    groupedData: false,
  },
  { type: 'operating_income',
    name: 'Operating Income',
    chartId: 1,
    groupedData: false,
  },
  { type: 'gross_margin',
    name: 'Gross Margin',
    chartId: 3,
    groupedData: false,
  },
  { type: 'operating_margin',
    name: 'Operating Margin',
    chartId: 3,
    groupedData: false,
  },
  { type: 'profit_margin',
    name: 'Profit Margin',
    chartId: 3,
    groupedData: false,
  },
  { type: 'net_income',
    name: 'Net Income',
    chartId: 2,
    groupedData: false,
  },
  { type: 'operating_expenses',
    name: 'Operating Expenses',
    chartId: 4,
    groupedData: true,
  },
  { type: 'revenue_y',
    name: 'Revenue Y/Y',
    chartId: 5,
    groupedData: false,
  },
  { type: 'opex',
    name: 'OpEx Y/Y',
    chartId: 5,
    groupedData: false,
  },
  { type: 'market_cap',
    name: 'Market Cap',
    chartId: 9,
    groupedData: false,
  },
  { type: 'outstanding_shares',
    name: 'Outstanding Shares',
    chartId: 8,
    groupedData: false,
  },
  { type: 'revenue_multiple',
    name: 'Revenue Multiple',
    chartId: 10,
    groupedData: false,
  },
  { type: 'all',
    name: 'All',
    groupedData: false,
  },
];

function ComparePage() {
  const [compareItem, setCompareItem] = useState(compareItems[0].type);
  const [compareCompanies, setCompareCompanies] = useState([]);
  const compareChartData = useSelector(selectorsAsssets.compareChartsData);
  const isLoading = useSelector(selectorsAsssets.isLoading);

  const addToCompareList = useCallback((value) => {
    if (!compareCompanies.includes(value)) {
      const newData = [...compareCompanies];
      newData.push(value);
      setCompareCompanies(newData);
      actionsAssets.getCompareData(value);
    }
  }, [compareCompanies]);

  const onDelete = (code) => {
    const newArray = compareCompanies.filter((value) => value !== code);
    setCompareCompanies(newArray);
    dispatchAction(sliceAssets.actions.removeCompareData, { code });
  };

  const compareCharts = useMemo(() => {
    const charts = {};
    if (!isLoading && compareCompanies.length > 0) {
      compareItems.map((item, index) => {
        if (item.type !== 'all') {
          const newChartData = { y_axis: [], order: index };
          Object.entries(compareChartData).forEach(([company, data]) => {
            const companyChart = data[item.chartId];
            if (companyChart) {
              if (!newChartData.x_axis) {
                newChartData.x_axis = companyChart?.x_axis?.quarterly;
                newChartData.type = companyChart.type;
                newChartData.title = item.name;
              }
              const yaxisData = companyChart?.y_axis?.quarterly;
              if (yaxisData) {
                if (item.groupedData) {
                  yaxisData.map((i) => {
                    const temp = { ...i };
                    temp.label = `${company} - ${i.label}`;
                    temp.group = company;
                    return newChartData.y_axis.push(temp);
                  });
                } else {
                  const correctChart = yaxisData ? yaxisData.filter((e) => e.label === item.name) : [];

                  if (correctChart.length > 0) {
                    const temp = { ...correctChart[0] };

                    temp.label = company;
                    newChartData.y_axis.push(temp);
                  }
                }
              }
            }
          });
          charts[item.type] = newChartData;
        }
        return true;
      });
    }
    const sortedData = Object.fromEntries(
      Object.entries(charts).sort(([, a], [, b]) => a.order - b.order),
    );
    return sortedData;
  }, [compareChartData, isLoading, compareCompanies]);

  const displayedCharts = useMemo(() => {
    if (compareItem === 'all') {
      return !isLoading && Object.keys(compareCharts).map((key) => {
        if (compareCharts[key]?.x_axis) {
          return <StackedBarChart key={`${key}${Date.now()}`} chartData={compareCharts[key]} />;
        }
        return null;
      });
    }
    if (compareCharts[compareItem] && compareCharts[compareItem].x_axis) {
      return <StackedBarChart key={`${compareItem}${Date.now()}`} chartData={compareCharts[compareItem]} />;
    }
    return null;
  }, [isLoading, compareCharts, compareItem]);
  const disableAutocomplete = useMemo(() => compareCompanies.length >= 4, [compareCompanies]);
  return (
    <Layout>
      <>
        <div className={styles.compareItems}>
          <div className={styles.selectedItems}>
            {compareCompanies.map(((item) => <Chip key={item} label={item} variant="outlined" onDelete={() => onDelete(item)} />))}
          </div>

          <div className={styles.autocomplete}>
            <AutoCompleteAssetsField disabled={disableAutocomplete} fontStyle={styles.autocompleteItem} size="small" onChange={addToCompareList} />
          </div>

          <TextField
            className={styles.select}
            // label="Type"
            variant="outlined"
            name="chart_type_id"
            select
            sx={{
              '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                fontSize: 12,
              },
              '.MuiInputBase-root': {
                lineHeight: 'unset!important',
              },
              '&.MuiAutocomplete-noOptions': {
                fontSize: 12,
              },
            }}
            size="small"
            InputProps={{
              classes: {
                input: styles.resize,
              },
            }}
            defaultValue={compareItems[0].type}
            onChange={(event) => setCompareItem(event.target.value)}
          >
            {compareItems.map((element) => (
              <MenuItem
                key={element.type}
                value={element.type}
                className={styles.resize}
              >
                {element.name}
              </MenuItem>
            ))}
          </TextField>

        </div>
        <div className={styles.charts}>
          {isLoading && (
            <div className={styles.progressBlock}><CircularProgress className={styles.progress} /></div>
          )}
          {displayedCharts}
        </div>
      </>

    </Layout>

  );
}

ComparePage.propTypes = {

};
export default ComparePage;
