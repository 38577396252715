/* eslint-disable comma-dangle */
/* eslint-disable operator-linebreak */
/* eslint-disable arrow-body-style */
/* eslint-disable quotes */
/* eslint-disable no-unused-vars */
import Api from '../../utils/Api';
import { getRefreshToken, removeAuthHeader, removeHeadersByRole, ROLES, setAuthHeader, } from '../../utils/apiService';
import sliceAuth from '../slices/sliceAuth';
import apiURL from '../../constants/apiURL';
import { dispatchAction } from '../store';
import actionsProfile from './profile';
import actionsAssets from './assets';

const actionsAuth = {};

actionsAuth.signIn = ({
  remember,
  password,
  email
}) => {
  return Api.post({
    url: apiURL.authSignIn(),
    action: sliceAuth.actions.signIn,
    requestData: {
      password,
      email,
    },
  })
    .then((response) => {
      const tokenAccess = response.access_token;
      const tokenRefresh = response.refresh_token;
      setAuthHeader({
        token: tokenAccess,
        tokenRefresh
      }, remember, ROLES.user);
      // dispatchAction(actionsAuth.getSettings());
      actionsProfile.getFavorites();
      actionsAuth.getProfileData();
      removeHeadersByRole(ROLES.admin);
      return response;
    });
};

actionsAuth.tokenRefresh = () => {
  return Api.post({
    url: apiURL.tokenRefresh(),
    action: sliceAuth.actions.refreshToken,
    requestData: {
      refresh_token: getRefreshToken(ROLES.user),
    },
  })
    .then((response) => {
      const tokenAccess = response.access_token;
      const tokenRefresh = response.refresh_token;
      setAuthHeader({
        token: tokenAccess,
        tokenRefresh
      }, true, ROLES.user);
      actionsAuth.getProfileData();
      actionsProfile.getFavorites();
      return response;
    });
};

actionsAuth.signUp = (requestData) => {
  return Api.post({
    url: apiURL.authSignUp(),
    action: sliceAuth.actions.signUp,
    requestData,
  })
    .then((response) => {
      return response;
    });
};

actionsAuth.signOut = (dispatch) => {
  return Api.post({
    url: apiURL.authLogout(),
    action: sliceAuth.actions.signOut,
    requestData: {
      refresh_token: getRefreshToken(),
    },
  }).then((response) => {
    removeAuthHeader();
    return response;
  });
};

actionsAuth.forgotPassword = ({ email }) => {
  return Api.post({
    url: apiURL.authForgotPassword(),
    action: sliceAuth.actions.forgotPassword,
    requestData: { email },
  });
};

actionsAuth.resendEmail = (email) => {
  return Api.post({
    url: apiURL.authResendEmail(),
    action: sliceAuth.actions.resendEmail,
    requestData: { email },
  });
};

actionsAuth.updatePhone = (requestData) => {
  return Api.post({
    url: apiURL.profile(),
    action: sliceAuth.actions.updatePhone,
    requestData,
  });
};

actionsAuth.changePassword = (requestData) => {
  return Api.post({
    url: apiURL.profileChangePassword(),
    action: sliceAuth.actions.changePassword,
    requestData,
  });
};

actionsAuth.resetPassword = (requestData) => {
  return Api.post({
    url: apiURL.authResetPassword(),
    action: sliceAuth.actions.resetPassword,
    requestData,
  });
};

actionsAuth.resetPasswordConfirm = (requestData) => {
  return Api.post({
    url: apiURL.authResetPasswordConfirm(),
    action: sliceAuth.actions.resetPasswordConfirm,
    requestData,
  });
};

actionsAuth.updateProfile = (requestData) => {
  const {
    avatar,
    birthday,
    ...rest
  } = requestData;
  const data = rest;
  data.birthday = birthday;

  const isDeletePhoto =
    (typeof avatar === 'string' && avatar?.length === 0) || avatar === null;
  const isDeleteBirthday =
    (typeof birthday === 'string' && birthday?.length === 0) ||
    birthday === null;

  if (isDeletePhoto) {
    data.avatar = null;
  }
  if (isDeleteBirthday) {
    data.birthday = null;
  }

  return Api.put({
    url: apiURL.profile(),
    action: sliceAuth.actions.updateProfile,
    requestData: data,
  });
};

actionsAuth.passwordChange = (requestData) => {
  return Api.post({
    url: apiURL.authPasswordChange(),
    action: sliceAuth.actions.updateProfile,
    requestData,
  });
};

actionsAuth.emailChange = (requestData) => {
  return Api.post({
    url: apiURL.authEmailChange(),
    action: sliceAuth.actions.updateProfile,
    requestData,
  });
};

actionsAuth.registerEmail = (requestData) => {
  return Api.post({
    url: apiURL.authRegisterEmail(),
    // action: sliceAuth.actions.confirmEmail,
    requestData,
  });
};

actionsAuth.verifyEmail = (requestData) => {
  return Api.post({
    url: apiURL.authVerifyEmail(),
    // action: sliceAuth.actions.confirmEmail,
    requestData,
  });
};

actionsAuth.confirmEmail = (requestData) => {
  return Api.post({
    url: apiURL.authConfirmEmail(),
    action: sliceAuth.actions.confirmEmail,
    requestData,
  });
};

actionsAuth.verifyRegistartion = (requestData) => {
  return Api.post({
    url: apiURL.authVerifyRegistration(),
    // action: sliceAuth.actions.confirmEmail,
    requestData,
  });
};

actionsAuth.deleteProfile = () => {
  return Api.delete({
    url: apiURL.authDeleteProfile(),
    action: sliceAuth.actions.deleteProfile,
  })
    .then((response) => {
      removeAuthHeader();
      return response;
    });
};

actionsAuth.setInit = () => {
  dispatchAction(sliceAuth.actions.setInit);
};

actionsAuth.sendMessage = (requestData) => {
  return Api.post({
    url: apiURL.contact(),
    requestData,
  });
};

actionsAuth.getProfileData = () => {
  return Api.get({
    url: apiURL.authProfile(),
    action: sliceAuth.actions.getProfile,
  });
};

actionsAuth.settingsChange = (requestData) => {
  return Api.patch({
    url: apiURL.settings(),
    action: sliceAuth.actions.setSettings,
    requestData,
  });
};

actionsAuth.confirmEmailUnsubscribe = (requestData) => {
  return Api.post({
    url: apiURL.confirmUnsubscribe(),
    requestData,
  });
};

export default actionsAuth;
