/* eslint-disable operator-linebreak */
/* eslint-disable quotes */
export const localDevelopment =
  process.env.REACT_APP_LOCAL_DEVELOPMENT === "TRUE";

const BASE_API_ENDPOINT = process.env.REACT_APP_API_URL;

const API_AUTH = `${BASE_API_ENDPOINT}accounts/`;
const API_EMAIL = `${BASE_API_ENDPOINT}email/`;
const API_PASSWORD = `${BASE_API_ENDPOINT}password/`;
const API_TOKEN = `${BASE_API_ENDPOINT}token/`;

export const DOMAIN = global.location ? global.location.origin : "";

const apiURL = {
  tokenCreate: () => `${API_TOKEN}`,
  tokenRefresh: () => `${API_AUTH}refresh/`,
  // Auth
  profilePhoto: () => `${API_AUTH}profile/`,
  profileChangePassword: () => `${API_AUTH}change-password/`,
  authDeleteProfile: () => `${API_AUTH}delete/`,
  authSignIn: () => `${API_AUTH}sign-in/`,
  authSignUp: () => `${API_AUTH}sign-up/`,
  authLogout: () => `${API_AUTH}sign-out/`,
  authForgotPassword: () => `${API_AUTH}reset-password/`,
  // Email
  authResendEmail: () => `${API_AUTH}resend-email/`,
  authEmailChange: () => `${API_EMAIL}change/`,
  authResetPassword: () => `${API_PASSWORD}reset/`,
  authResetPasswordConfirm: () => `${API_PASSWORD}reset/confirm/`,
  authPasswordChange: () => `${API_AUTH}change-password/`,
  authConfirmEmail: () => `${API_EMAIL}confirm-email/`,
  authRegisterEmail: () => `${API_EMAIL}register-email/`,
  authVerifyEmail: () => `${API_AUTH}verify/`,
  authProfile: () => `${API_AUTH}profile/`,
  authVerifyRegistration: () => `${API_EMAIL}verify/`,
  getAssets: ({ page, size, query, orderBy, isDesc }) => `${BASE_API_ENDPOINT}assets/?size=${size}&page=${page}&q=${query}&order_by=${orderBy}&desc=${isDesc}`,
  getFavorites: () => `${BASE_API_ENDPOINT}assets/favorites/`,
  getFavoriteNews: ({ page, size }) => `${BASE_API_ENDPOINT}articles/favorite_assets_articles/?page=${page}&size=${size}`,
  addFavorites: () => `${BASE_API_ENDPOINT}assets/favorites/add`,
  removeFavorites: () => `${BASE_API_ENDPOINT}assets/favorites/remove`,
  getAssetDetails: (code) => `${BASE_API_ENDPOINT}assets/${code}/`,
  getChartData: (code, id) => `${BASE_API_ENDPOINT}assets/${code}/chart/${id}/`,
  downloadFile: (code, id, period) => `${BASE_API_ENDPOINT}assets/${code}/download_file/${id}/?qt=${period}`,
  getNews: ({ page, size, code }) => `${BASE_API_ENDPOINT}articles/${code}/?page=${page}&size=${size}`,
  getPriceChart: (code, period) => `${BASE_API_ENDPOINT}assets/${code}/prices/${period}/`,
  getCalendar: ({ dateFrom, dateTo }) => `${BASE_API_ENDPOINT}assets/calendar/?date_from=${dateFrom}&date_to=${dateTo}`,
  autocompleteSearch: (query) => `${BASE_API_ENDPOINT}assets/autocomplete/${query}`,
};

export default apiURL;
