import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import { ToastContainer } from 'react-toastify';
import Routes from './routes';
import useRoutes from './routes/hooks';
import { ModalProvider } from './context/ModalContext';
import ModalRoot from './components/modals/ModalRoot';

import 'react-toastify/dist/ReactToastify.css';

function App() {
  useRoutes();
  return (
    <BrowserRouter>
      <ModalProvider>
        <Routes />
        <ModalRoot />
      </ModalProvider>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
