/* eslint-disable no-unused-expressions */
/* eslint-disable quotes */
import axios from 'axios';

export const HTTP_CODE = {
  HTTP_400_BAD_REQUEST: 400,
  HTTP_401_UNAUTHORIZED: 401,
  HTTP_403_FORBIDDEN: 403,
  HTTP_404_NOT_FOUND: 404,
  HTTP_500_INTERNAL_SERVER_ERROR: 500,
  HTTP_502_BAD_GATEWAY: 502,
};

const LocalStorage = global.localStorage ? localStorage : null;
const SessionStorage = global.sessionStorage ? sessionStorage : null;

export const KEY_TOKEN = 'auth';
export const KEY_ADMIN_TOKEN = 'auth-admin';
export const KEY_TOKEN_REFRESH = 'auth-refresh';
export const KEY_ADMIN_TOKEN_REFRESH = 'auth-admin-refresh';

export const ROLES = {
  user: 'user',
  admin: 'admin',
};

const getTokenByRole = (role) => {
  if (role === ROLES.admin) {
    return KEY_ADMIN_TOKEN;
  }
  return KEY_TOKEN;
};

const getRefreshTokenByRole = (role) => {
  if (role === ROLES.admin) {
    return KEY_ADMIN_TOKEN_REFRESH;
  }
  return KEY_TOKEN_REFRESH;
};

const getTokenByStorage = () => {
  if (LocalStorage.getItem(KEY_ADMIN_TOKEN)) {
    return ROLES.admin;
  }
  return ROLES.user;
};

export const getToken = (key = KEY_TOKEN) => {
  const localAuth = LocalStorage?.getItem(key);
  if (localAuth && localAuth !== 'undefined') {
    return localAuth;
  }
  const sessionAuth = SessionStorage?.getItem(key);
  if (sessionAuth && sessionAuth !== 'undefined') {
    return sessionAuth;
  }
  return '';
};

export const getRefreshToken = (role) => getToken(getRefreshTokenByRole(role));

const getBearerToken = (role) => {
  const token = getToken(getTokenByRole(role));
  return token ? `Bearer ${token}` : '';
};

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.baseURL = '/';

axios.defaults.headers.common.Authorization = getBearerToken(getTokenByStorage());

export const setAuthHeader = ({
  token,
  tokenRefresh,
}, remember, role) => {
  if (LocalStorage) {
    if (remember || localStorage.getItem(getTokenByRole(role))) {
      token && localStorage.setItem(getTokenByRole(role), token);
      tokenRefresh && localStorage.setItem(getRefreshTokenByRole(role), tokenRefresh);
    } else {
      token && SessionStorage.setItem(getTokenByRole(role), token);
      tokenRefresh && SessionStorage.setItem(getRefreshTokenByRole(role), tokenRefresh);
    }
  }
  axios.defaults.headers.common.Authorization = getBearerToken(role);
};

export const removeAuthHeader = () => {
  if (LocalStorage) {
    LocalStorage.removeItem(KEY_TOKEN);
    LocalStorage.removeItem(KEY_ADMIN_TOKEN);
    LocalStorage.removeItem(KEY_TOKEN_REFRESH);
    LocalStorage.removeItem(KEY_ADMIN_TOKEN_REFRESH);
    SessionStorage.removeItem(KEY_TOKEN);
    SessionStorage.removeItem(KEY_ADMIN_TOKEN);
    SessionStorage.removeItem(KEY_TOKEN_REFRESH);
    SessionStorage.removeItem(KEY_ADMIN_TOKEN_REFRESH);
  }
  axios.defaults.headers.common.Authorization = '';
};

export const removeHeadersByRole = (role) => {
  if (LocalStorage) {
    LocalStorage.removeItem(getTokenByRole(role));
    LocalStorage.removeItem(getRefreshTokenByRole(role));
    SessionStorage.removeItem(getTokenByRole(role));
    SessionStorage.removeItem(getRefreshTokenByRole(role));
  }
};

export const setLanguageParam = (code) => {
  if (!axios.defaults.params) {
    axios.defaults.params = {};
  }
  axios.defaults.params.language = (code || '').toUpperCase();
};

export default axios;
