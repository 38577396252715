import React, { useCallback, useEffect } from 'react';
import { FieldArray, Form, Formik } from 'formik';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { toast } from 'react-toastify';
import { MenuItem } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { companiesSelectors } from '../../../redux/selectors/companies';
import actionsCompanies from '../../../redux/actions/companies';
import adminRoutes from '../../../constants/adminRoutes';
import actionsCustomCharts from '../../../redux/actions/customCharts';
import { customChartsSelectors } from '../../../redux/selectors/customCharts';

import styles from './styles.module.scss';
import AutoCompletField from '../inputs/Autocomplete';
import useValidation from '../validations/useValidation';
import customChartsSchema from '../validations/customChartsSchema';

function FormCreateCustomProp() {
  const { id } = useParams();
  const navigate = useNavigate();
  const selectedCompany = useSelector(companiesSelectors.getSelectedCompany);
  const chartTypes = useSelector(customChartsSelectors.getChartTypes);

  const handleSubmit = useCallback(
    (values) => {
      actionsCustomCharts
        .createCustomChart(values)
        .then(() => {
          toast.success('Created successfully!');

          if (id) {
            navigate(-1);
            return;
          }

          navigate(adminRoutes.CUSTOM_CHARTS);
        })
        .catch((error) => {
          if (error?.detail) {
            toast.error(error?.detail);
          }
          if (error?.non_field_errors) {
            toast.error(error?.non_field_errors?.join('\n'));
          }
        });
    },
    [id, navigate],
  );

  const { scheme } = useValidation({
    scheme: customChartsSchema,
    requiredFields: {
      title: true,
      asset_code: true,
      chart_type_id: true,
    },
  });

  const initialValues = {
    title: '',
    asset_code: (selectedCompany && id) ? selectedCompany.code : '',
    chart_type_id: '',
    units: '',
    x_axis: {
      data: [null],
    },
    y_axis: [{
      label: '',
      group: '',
      data: '',
    }],
    ordering: '',
    is_active: true,
  };

  useEffect(() => {
    if (id) {
      actionsCompanies.getCompanyByCode(id);
    }
  }, [id]);

  useEffect(() => {
    if (id && !selectedCompany) {
      navigate(adminRoutes.COMPANIES);
    }
  }, [id, navigate, selectedCompany]);

  useEffect(() => {
    actionsCustomCharts.getChartTypes();
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={scheme}
    >
      {({
        values,
        handleChange,
        setFieldValue,
        touched,
        errors,
      }) => (
        <Form>
          <Typography
            variant="h4"
            align="center"
            marginBottom="20px"
          >
            {(id && selectedCompany) ? `Create custom prop for ${selectedCompany.code}` : 'Create custom prop'}
          </Typography>
          <div className={styles.column}>
            <AutoCompletField
              onChange={setFieldValue}
              defaultValue={{ code: values.asset_code, name: selectedCompany?.name }}
              disabled={!!(id && selectedCompany)}
              error={touched.asset_code && Boolean(errors.asset_code)}
              helperText={touched.asset_code && errors.asset_code}
            />
            {/* <TextField
              label="Company"
              variant="outlined"
              name="asset_code"
              defaultValue={values.asset_code}
              disabled={!!(id && selectedCompany)}
              onChange={handleChange}
            /> */}
            <div className={styles.row}>
              <div className={styles.column}>
                <TextField
                  label="Chart name"
                  variant="outlined"
                  name="title"
                  onChange={handleChange}
                  error={touched.title && Boolean(errors.title)}
                  helperText={touched.title && errors.title}
                />

                <TextField
                  label="Units"
                  variant="outlined"
                  name="units"
                  onChange={handleChange}
                  error={touched.units && Boolean(errors.units)}
                  helperText={touched.units && errors.units}
                />
              </div>

              <div className={styles.column}>
                <TextField
                  label="Type"
                  variant="outlined"
                  name="chart_type_id"
                  select
                  defaultValue=""
                  onChange={handleChange}
                  error={touched.chart_type_id && Boolean(errors.chart_type_id)}
                  helperText={touched.chart_type_id && errors.chart_type_id}
                >
                  {chartTypes.map((element) => (
                    <MenuItem
                      key={element.id}
                      value={element.id}
                    >
                      {element.name}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  type="number"
                  label="Ordering"
                  variant="outlined"
                  name="ordering"
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className={styles.row}>
              <FieldArray name="x_axis.data">
                {({
                  remove,
                  push,
                }) => (
                  <div className={styles.column}>
                    <Typography
                      variant="h4"
                      align="left"
                      marginBottom="20px"
                    >
                      X Axis
                    </Typography>

                    {values.x_axis.data.map((el, index) => (
                      <div className={styles.column}>
                        {values.x_axis.data.length > 1 && (
                          <Button
                            color="secondary"
                            type="button"
                            variant="outlined"
                            className={styles.controlButton}
                            onClick={() => remove(index)}
                          >
                            Delete
                          </Button>
                        )}
                        <TextField
                          label="Name"
                          name={`x_axis.data.${index}`}
                          variant="outlined"
                          onChange={handleChange}
                        />
                      </div>
                    ))}
                    <Button
                      fullWidth
                      size="large"
                      type="button"
                      variant="contained"
                      color="success"
                      onClick={() => push()}
                    >
                      Add row
                    </Button>
                  </div>
                )}
              </FieldArray>

              <FieldArray name="y_axis">
                {({
                  remove,
                  push,
                }) => (
                  <div className={styles.column}>
                    <Typography
                      variant="h4"
                      align="left"
                      marginBottom="20px"
                    >
                      Datasets
                    </Typography>
                    <div className={styles.row}>
                      {values.y_axis.map((el, index) => (
                        <div className={styles.column}>
                          {values.y_axis.length > 1 && (
                            <Button
                              color="secondary"
                              type="button"
                              variant="outlined"
                              className={styles.controlButtonColumn}
                              onClick={() => remove(index)}
                            >
                              Delete
                            </Button>
                          )}
                          <div className={styles.column}>
                            <TextField
                              label="Label name"
                              name={`y_axis.${index}.label`}
                              variant="outlined"
                              onChange={handleChange}
                            />
                            <TextField
                              label="Group"
                              name={`y_axis.${index}.group`}
                              variant="outlined"
                              onChange={handleChange}
                            />
                            <FieldArray name={`y_axis.${index}.data`}>
                              <>
                                {values.x_axis.data.map((_, idx) => (
                                  <TextField
                                    label="Data"
                                    type="number"
                                    name={`y_axis.${index}.data.${idx}`}
                                    variant="outlined"
                                    onChange={handleChange}
                                  />
                                ))}
                              </>
                            </FieldArray>

                          </div>
                        </div>
                      ))}
                    </div>
                    <Button
                      fullWidth
                      size="large"
                      type="button"
                      variant="contained"
                      color="success"
                      onClick={() => push({
                        label: '',
                        group: '',
                        data: '',
                      })}
                    >
                      Add column
                    </Button>
                  </div>
                )}
              </FieldArray>
            </div>
          </div>
          <div className={styles.buttonWrapper}>
            <Button
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Create
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default FormCreateCustomProp;
