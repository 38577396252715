import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import AdminLayout from '../../components/AdminLayout';
import actionsCompanies from '../../redux/actions/companies';
import { companiesSelectors } from '../../redux/selectors/companies';
import AdminCompanyTable from '../../components/AdminCompanyTable';
import AdminCompanyCustomChartTable from '../../components/CustomChartCompanyTable';

import styles from './styles.module.scss';

function AdminCompany() {
  const { id } = useParams();
  const selectedCompany = useSelector(companiesSelectors.getSelectedCompany);

  const columns = [
    {
      id: 'name',
      name: 'Name',
      sort: false,
    },
    {
      id: 'exchange.code',
      name: 'Exchange Code',
      sort: false,
    },
    {
      id: 'exchange.name',
      name: 'Exchange name',
      sort: false,
    },
    {
      id: 'exchange.countryiso2',
      name: 'Exchange county ISO',
      sort: false,
    },
    {
      id: 'controls',
      name: 'Controls',
      sort: false,
    },
  ];

  const chartColumns = [
    {
      id: 'id',
      name: 'ID',
      sort: false,
    },
    {
      id: 'title',
      name: 'Title',
      sort: false,
    },
    {
      id: 'chart_type',
      name: 'Chart type',
      sort: false,
    },
    {
      id: 'is_active',
      name: 'Active',
      sort: false,
    },
    {
      id: 'is_custom',
      name: 'Custom',
      sort: false,
    },
    {
      id: 'ordering',
      name: 'Ordering',
      sort: false,
    },
    {
      id: 'y_axis',
      name: 'Y Axis',
      sort: false,
    },
    {
      id: 'control',
      name: 'Control',
      sort: false,
    },
  ];

  useEffect(() => {
    actionsCompanies.getCompanyByCode(id);
  }, [id]);

  return (
    <AdminLayout>
      <div className={styles.wrapper}>
        <div>
          <Typography
            variant="h4"
            align="left"
          >
            Company
          </Typography>
          {selectedCompany && (
            <AdminCompanyTable
              company={[selectedCompany]}
              columns={columns}
            />
          )}
        </div>
        {selectedCompany?.custom_charts.length
          ? (
            <div>
              <Typography
                variant="h4"
                align="left"
              >
                Charts
              </Typography>
              <AdminCompanyCustomChartTable
                companyId={id}
                company={selectedCompany.custom_charts}
                columns={chartColumns}
              />
            </div>
          )
          : null}
      </div>
    </AdminLayout>
  );
}

AdminCompany.propTypes = {};
export default AdminCompany;
