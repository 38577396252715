import { createSelector } from '@reduxjs/toolkit';

const companiesState = (state) => state.companies;

const getCompanies = createSelector(
  companiesState,
  (state) => state.companies,
);

const getSelectedCompany = createSelector(
  companiesState,
  (state) => state.selectedCompany,
);

const getCount = createSelector(
  companiesState,
  (state) => state.count,
);

const getSelectedPage = createSelector(
  companiesState,
  (state) => state.selectedPage,
);

// eslint-disable-next-line import/prefer-default-export
export const companiesSelectors = {
  getCompanies,
  getSelectedCompany,
  getCount,
  getSelectedPage,
};
