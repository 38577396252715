import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { getRefreshToken } from '../utils/apiService';
import actionsAuth from '../redux/actions/auth';

const isAuthSelector = (state) => state.auth.isAuthorized;

const selector = createSelector(isAuthSelector, (isAuth) => ({
  isAuth,
}));

const useRoutes = () => {
  const data = useSelector(selector);
  useEffect(() => {
    const refreshToken = getRefreshToken();
    if (refreshToken) {
      actionsAuth
        .tokenRefresh()
        .catch(() => actionsAuth.setInit());
    } else {
      actionsAuth.setInit();
    }
  }, []);

  return {
    data,
  };
};

export default useRoutes;
