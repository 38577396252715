import { createSelector } from '@reduxjs/toolkit';

const usersState = (state) => state.users;

const getUsers = createSelector(
  usersState,
  (state) => state.users,
);

const getCount = createSelector(
  usersState,
  (state) => state.count,
);

const getSelectedPage = createSelector(
  usersState,
  (state) => state.selectedPage,
);

// eslint-disable-next-line import/prefer-default-export
export const usersSelectors = {
  getUsers,
  getCount,
  getSelectedPage,
};
