/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useCallback, useContext, useState } from 'react';
import { useFormik } from 'formik';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import authSchema from '../validations/authSchema';
import useValidation from '../validations/useValidation';
import styles from './styles.module.scss';

// import styles from './styles.module.scss';
import actionsAuth from '../../../redux/actions/auth';
import { ModalContext } from '../../../context/ModalContext';
import FormSignIn from '../SignIn';
import FormEmailVerification from '../EmailVerification';
// import routes from '../../../constants/routes';

function FormChangePassword() {
  const [loading, setLoading] = useState(false);
  const { hideModal } = useContext(ModalContext);
  // const [commonError, setCommonError] = useState('');

  const { scheme } = useValidation({
    scheme: authSchema,
    requiredFields: {
      old_password: true,
      new_password: true,
      new_password_confirm: true,
    },
  });

  const handleSubmit = useCallback(
    (values, { setFieldError }) => {
      setLoading(true);
      actionsAuth
        .changePassword(values)
        .then(() => {
          setLoading(false);
          hideModal();
          toast.success('Password was successfully updated');
        })
        .catch((error) => {
          setLoading(false);
          if (error?.detail) {
            toast.error(error.detail);
          } else {
            toast.error('Something went wrong, please try again later');
          }
        });
    },
    [hideModal],
  );

  const formik = useFormik({
    initialValues: {
      old_password: '',
      new_password: '',
      new_password_confirm: '',
    },
    validationSchema: scheme,
    onSubmit: handleSubmit,
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <Typography variant="h4">
        Change Password
      </Typography>
      <div className={styles.inputWrapper}>
        <TextField
          label="Old Password"
          type="password"
          variant="outlined"
          name="old_password"
          value={formik.values.old_password}
          onChange={formik.handleChange}
          error={formik.touched.old_password && Boolean(formik.errors.old_password)}
          helperText={formik.touched.old_password && formik.errors.old_password}
        />
        <TextField
          label="New password"
          type="password"
          variant="outlined"
          name="new_password"
          value={formik.values.new_password}
          onChange={formik.handleChange}
          error={formik.touched.new_password && Boolean(formik.errors.new_password)}
          helperText={formik.touched.new_password && formik.errors.new_password}
        />
        <TextField
          label="Confirm password"
          type="password"
          variant="outlined"
          name="new_password_confirm"
          value={formik.values.new_password_confirm}
          onChange={formik.handleChange}
          error={formik.touched.new_password_confirm && Boolean(formik.errors.new_password_confirm)}
          helperText={formik.touched.new_password_confirm && formik.errors.new_password_confirm}
        />
      </div>
      <div className={styles.buttonWrapper}>
        <Button size="large" fullWidth type="submit" variant="contained">Change</Button>
      </div>
    </form>
  );
}

export default FormChangePassword;
