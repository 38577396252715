/* eslint-disable quotes */
const AUTH = "/auth/";

const routes = {
  HOME_PAGE: "/",
  EMAIL_UNSUBSCRIBE: '/unsubscribe',
  AUTH,
  SIGN_UP: `${AUTH}sign-up/`,
  SIGN_IN: `${AUTH}sign-in/`,
  FORGOT_PASSWORD: `${AUTH}forgot-password/`,
  PASSWORD_CHANGE: `${AUTH}password-change/`,
  PASSWORD_RESET_CONFIRM: `${AUTH}password/reset/confirm/:uid/:token/`,
  FORGOT_PASSWORD_SUCCESS: `${AUTH}forgot-password-success/`,
  RESET_PASSWORD: `"${AUTH}reset-password/"`,
  RESET_PASSWORD_FAIL: `${AUTH}reset-fail/`,
  CONFIRM_REGISTER: `${AUTH}activate-email/:key/`,
  CONFIRM_EMAIL: `/verify_email/`,
  CHANGE_EMAIL: `${AUTH}change-email/`,
  ACCOUNT: `/account`,

  COMPANY_PAGE: '/company/:asset/',
  NEWS_PAGE: '/news/',
  COMPANIES_PAGE: '/companies/',
  COMPARE_PAGE: '/compare/',
  CALENDAR_PAGE: '/calendar/',
};

export default routes;
