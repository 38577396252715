import Api from '../../utils/Api';
import sliceUsers from '../slices/users';
import adminApiURL from '../../constants/adminApiURL';
import { dispatchAction } from '../store';

const actionsUsers = {};

actionsUsers.getUsers = ({
  q = '',
  page = 1,
  size = 5,
  orderBy = 'date_join',
  desc = true,
}) => Api.get({
  url: adminApiURL.getUsers({
    q,
    page,
    size,
    order_by: orderBy,
    desc,
  }),
  action: sliceUsers.actions.setUsers,
  withHeaders: true,
})
  .then((response) => {
    dispatchAction(sliceUsers.actions.setCount, Number(response.headers['x-total-items']));
  });

actionsUsers.getUserById = (id) => Api.get({
  url: adminApiURL.getUserById(id),
  action: sliceUsers.actions.setUsers,
});

actionsUsers.updateUser = (id, requestData) => Api.patch({
  url: adminApiURL.updateUserById(id),
  requestData,
});

export default actionsUsers;
