/* eslint-disable react/prop-types */
import * as React from 'react';
import cn from 'classnames';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { Link } from 'react-router-dom';
import { columns } from './mockData';
import styles from './styles.module.scss';
import routes from '../../constants/routes';
import actionsProfile from '../../redux/actions/profile';
import { parseRoutePath } from '../../utils/routing';
import formatNumber from '../../utils/number';
import { dispatchAction } from '../../redux/store';
import sliceAssets from '../../redux/slices/sliceAssets';

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, noSort } = props;
  const createSortHandler = (property, id) => (event) => {
    onRequestSort(event, property, id);
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.id === 'title' ? 'left' : 'right'}
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sort && !noSort ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.orderBy, headCell.id)}
              >
                {headCell.name}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : headCell.name}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function UITable({ data, isAuth, isFull, onSort, noSort }) {
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('market');

  const handleRequestSort = (
    event,
    property,
    id,
  ) => {
    const isDesc = orderBy === id && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(id);
    onSort(property, !isDesc);
  };
  const onStarClick = async (code, isFavorite) => {
    if (isFavorite) {
      await actionsProfile.removeFavorite({ code });
      dispatchAction(sliceAssets.actions.updateFavorite, { asset: code, isFull });
    } else {
      await actionsProfile.addFavorite({ code });
      dispatchAction(sliceAssets.actions.updateFavorite, { asset: code, isFull });
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <TableContainer>
        <Table
          className={styles.table}
          // sx={{ minWidth: 1050 }}
          aria-labelledby="tableTitle"
          size="medium"
        >
          {data.length === 0 && <caption>No data found</caption> }
          <EnhancedTableHead
            noSort={noSort}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {data
              .map((row) => (
                <TableRow
                  key={row.code}
                >
                  <TableCell>
                    {isAuth && (
                      <IconButton onClick={() => onStarClick(row.code, row.is_favorite)}>
                        {row.is_favorite ? (
                          <svg
                            width="16"
                            height="16"
                            focusable="false"
                            aria-hidden="true"
                            viewBox="0 0 24 24"
                            data-testid="StarIcon"
                            aria-label="fontSize small"
                            fill="#A7A7A9"
                          >
                            <path d="M12 17.27 18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
                          </svg>
                        ) : (
                          <svg
                            focusable="false"
                            aria-hidden="true"
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            data-testid="StarBorderIcon"
                            aria-label="fontSize small"
                            fill="#A7A7A9"
                          >
                            <path d="m22 9.24-7.19-.62L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.63-7.03L22 9.24zM12 15.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L12 6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4z" />

                          </svg>
                        )}
                      </IconButton>

                    )}
                  </TableCell>
                  <TableCell className={styles.companyTitle}>
                    <Link
                      to={parseRoutePath(routes.COMPANY_PAGE, { asset: row.code })}
                    >
                      {row.name}
                    </Link>
                  </TableCell>
                  <TableCell align="right">
                    <div className={styles.companyName}>
                      <span className={styles.exchange}>{`${row.exchange.code}: `}</span>
                      <span>{row.code}</span>
                    </div>

                  </TableCell>
                  <TableCell align="right">
                    {row.price.close === null && 'No data'}
                    {row.price.close !== null
                    && (
                    <div className={styles.price}>
                      <span>$</span>
                      <span>{formatNumber(row.price.close)}</span>
                    </div>
                    )}
                  </TableCell>
                  <TableCell
                    className={cn({
                      [styles.positive]: row.price.change_percent > 0,
                      [styles.negative]: row.price.change_percent < 0,
                    })}
                    align="right"
                  >
                    {`${row.price.change_percent === null ? 'No data' : `${row.price.change_percent > 0 ? '+' : ''}${formatNumber(row.price.change_percent)}%`}`}

                  </TableCell>
                  <TableCell align="right">
                    {row.price.market_cap === null && 'No data'}
                    {row.price.market_cap !== null && (
                      <div className={styles.price}>
                        <span>$</span>
                        <span>
                          {Intl.NumberFormat('en-GB', {
                            notation: 'compact',
                          }).format(row.price.market_cap)}

                        </span>
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
