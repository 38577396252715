const barOptions = (yaxis, xaxis, title, hideLegend, unit) => {
  const baseOptions = {
    color: [
      '#5297D4',
      '#E76051',
      '#E4C15A',
      '#64C686',
      '#BA5DD6',
    ],
    title: {
      text: title,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: { show: true },
      },
    },
    legend: {
      show: true,
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        data: xaxis?.data || ['FY18 Q4', 'FY18 Q4', 'FY18 Q4', 'FY18 Q4', 'FY18 Q4', 'FY18 Q4', 'FY18 Q4', 'FY18 Q4', 'FY18 Q4', 'FY18 Q4', 'FY18 Q4', 'FY18 Q4', 'FY18 Q4', 'FY18 Q4'],
        axisTick: { alignWithLabel: true },
      },
    ],
    yAxis: [
      {
        type: 'value',
        axisLabel: {
          formatter(value) {
            return `${Intl.NumberFormat('en-GB', {
              notation: 'compact',
            }).format(value)} ${unit || '$'}`;
          },
        },
      },
    ],
    series: [

    ],
  };
  if (yaxis && yaxis.length > 0) {
    yaxis.map((item) => baseOptions.series.push({
      name: item.label,
      type: 'bar',
      tooltip: {
        valueFormatter(value) {
          return `${Intl.NumberFormat('en-GB', {
            notation: 'compact',
            maximumFractionDigits: 2,
          }).format(value)} ${unit || '$'}`;
        },
      },
      data: item.data || [220, 182, 191, 234, 290, 330, 310, 120, 132, 101, 134, 90, 230, 210],
    }));
  } else {
    baseOptions.series.push({
      name: 'Price',
      type: 'bar',
      tooltip: {
        valueFormatter(value) {
          return `${Intl.NumberFormat('en-GB', {
            notation: 'compact',
            maximumFractionDigits: 2,
          }).format(value)} ${unit || '$'}`;
        },
      },
      data: [220, 182, 191, 234, 290, 330, 310, 120, 132, 101, 134, 90, 230, 210],
    });
  }
  return baseOptions;
};

export default barOptions;
