import React from 'react';
import PersonIcon from '../icons/PersonIcon';
import adminRoutes from '../../constants/adminRoutes';

const menuAdminItemsMain = [
  {
    title: 'Users',
    icon: <PersonIcon />,
    url: adminRoutes.USERS,
  },
  {
    title: 'Companies',
    icon: <PersonIcon />,
    url: adminRoutes.COMPANIES,
  },
  {
    title: 'Custom charts',
    icon: <PersonIcon />,
    url: adminRoutes.CUSTOM_CHARTS,
  },
  {
    title: 'Create custom chart',
    icon: <PersonIcon />,
    url: adminRoutes.CREATE_CUSTOM_CHART,
  },
];
export default menuAdminItemsMain;
