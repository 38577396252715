import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Pagination, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import AdminLayout from '../../components/AdminLayout';
import actionsCompanies from '../../redux/actions/companies';
import CompaniesTable from '../../components/CompaniesTable';
import { companiesSelectors } from '../../redux/selectors/companies';
import { dispatchAction } from '../../redux/store';
import sliceCompanies from '../../redux/slices/companies';
import styles from './styles.module.scss';

const COMPANIES_PER_PAGE = 5;

// TODO Debounce
function CompaniesList() {
  const companies = useSelector(companiesSelectors.getCompanies);
  const count = useSelector(companiesSelectors.getCount);
  const selectedPage = useSelector(companiesSelectors.getSelectedPage);

  const pagesCount = Math.ceil(count / COMPANIES_PER_PAGE);

  const columns = [
    {
      id: 'code',
      name: 'Code',
      sort: false,
    },
    {
      id: 'name',
      name: 'Name',
      sort: false,
    },
  ];

  const onSearchHandler = useCallback((string) => {
    actionsCompanies.getCompanies({
      q: string,
      size: COMPANIES_PER_PAGE,
    });
  }, []);

  const onChangePageHandler = useCallback((page) => {
    actionsCompanies.getCompanies({
      q: '',
      page,
      size: COMPANIES_PER_PAGE,
    });
    dispatchAction(sliceCompanies.actions.setSelectedPage, page);
  }, []);

  useEffect(() => {
    actionsCompanies.getCompanies({ size: COMPANIES_PER_PAGE });
  }, []);

  return (
    <AdminLayout>
      <TextField
        className={styles.input}
        fullWidth
        name="search"
        placeholder="search"
        onChange={(event) => onSearchHandler(event.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_0_815)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M22.8596 21.6246L19.0733 17.8382C19.0016 17.7665 18.908 17.729 18.8082 17.729H18.3965C19.3789 16.5906 19.9747 15.1092 19.9747 13.4873C19.9747 9.9037 17.071 7 13.4873 7C9.9037 7 7 9.9037 7 13.4873C7 17.071 9.9037 19.9747 13.4873 19.9747C15.1092 19.9747 16.5906 19.3789 17.729 18.3965V18.8082C17.729 18.908 17.7696 19.0016 17.8382 19.0733L21.6246 22.8596C21.7712 23.0062 22.0082 23.0062 22.1548 22.8596L22.8596 22.1548C23.0062 22.0082 23.0062 21.7712 22.8596 21.6246ZM13.4873 18.4776C10.7302 18.4776 8.49708 16.2444 8.49708 13.4873C8.49708 10.7302 10.7302 8.49708 13.4873 8.49708C16.2444 8.49708 18.4776 10.7302 18.4776 13.4873C18.4776 16.2444 16.2444 18.4776 13.4873 18.4776Z"
                    fill="#A7A7A9"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_0_815">
                    <rect width="30" height="30" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </InputAdornment>
          ),
        }}
      />

      {
        companies.length
          ? <CompaniesTable isFull companies={companies} columns={columns} />
          : <Typography variant="h6" align="center">No data found!</Typography>
      }

      {pagesCount > 1 && (
        <Pagination
          className={styles.pagination}
          count={pagesCount}
          size="large"
          color="secondary"
          page={selectedPage}
          variant="outlined"
          onChange={(_, page) => onChangePageHandler(page)}
        />
      )}
    </AdminLayout>
  );
}

CompaniesList.propTypes = {};
export default CompaniesList;
