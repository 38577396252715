import { configureStore, createAction } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import reducers from './slices';

const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) => {
    if (process.env.NODE_ENV === 'production') {
      return getDefaultMiddleware();
    }
    return getDefaultMiddleware().concat(logger);
  },
  devTools: process.env.NODE_ENV !== 'production',
});

export const dispatchAction = (action, payload) => {
  store.dispatch(createAction(`${action}`)(payload));
};

// eslint-disable-next-line prefer-destructuring
// export const dispatch = store.dispatch;

export default store;
