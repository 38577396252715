/* eslint-disable max-len */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
import React, { useRef, useEffect } from 'react';
import { init, getInstanceByDom } from 'echarts';
import options from './chartMock';
import lineOptions from './lineChartMock';
import barOptions from './barChartOptions';
import areaChartOptions from './areaChartOptions';

export const BAR_CHART = 1;
export const STACKED_BAR_CHART = 2;
export const LINE_CHARTS_SHARP = 3;
export const LINE_CHARTS = 4;
export const AREA_CHART = 5;

// const data = {
//   stackedBar: options(yaxis, xaxis.data, Object.keys(yaxis)),
//   line: lineOptions,
//   bar: barOptions,
// };

function StackedBarChart({
  option,
  style,
  settings,
  loading,
  theme,
  period,
  chartData,
  hideLegend = false,
}) {
  const chartRef = useRef(null);

  useEffect(() => {
    // Initialize chart
    let chart;
    if (chartRef.current !== null) {
      chart = init(chartRef.current, 'light');
    }

    // Add chart resize listener
    // ResizeObserver is leading to a bit janky UX
    function resizeChart() {
      chart?.resize();
    }
    window.addEventListener('resize', resizeChart);
    // Return cleanup function
    return () => {
      chart?.dispose();
      window.removeEventListener('resize', resizeChart);
    };
  }, []);

  useEffect(() => {
    // Update chart
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current);

      let chartOptions;
      const yaxis = period ? chartData?.y_axis[period] : chartData?.y_axis;
      const xaxis = period ? chartData?.x_axis[period] : chartData?.x_axis;
      if (!yaxis) {
        chart.showLoading({ text: 'loading',
          color: '#c23531',
          textColor: '#000',
          maskColor: 'rgba(255, 255, 255, 0.8)',
          zlevel: 0,

          // Font size. Available since `v4.8.0`.
          fontSize: 12,
          // Show an animated "spinner" or not. Available since `v4.8.0`.
          showSpinner: true,
          // Radius of the "spinner". Available since `v4.8.0`.
          spinnerRadius: 10,
          // Line width of the "spinner". Available since `v4.8.0`.
          lineWidth: 5,
          // Font thick weight. Available since `v5.0.1`.
          fontWeight: 'normal',
          // Font style. Available since `v5.0.1`.
          fontStyle: 'normal',
          // Font family. Available since `v5.0.1`.
          fontFamily: 'sans-serif' });
      } else {
        chart.hideLoading();
      }
      switch (chartData?.type) {
        case BAR_CHART:
          chartOptions = barOptions(yaxis, xaxis, chartData.title, hideLegend, chartData.unit);
          break;
        case LINE_CHARTS:
        case LINE_CHARTS_SHARP:
          chartOptions = lineOptions(yaxis, xaxis, chartData.title, hideLegend, chartData.unit, chartData?.type === LINE_CHARTS);
          break;
        case STACKED_BAR_CHART:
          chartOptions = options(yaxis, xaxis, chartData.title, hideLegend, chartData.unit);
          break;
        case AREA_CHART:
          chartOptions = areaChartOptions(yaxis, xaxis, chartData.title, hideLegend, chartData.unit);
          break;
        default:
          chartOptions = null;
      }
      chartOptions && chart.setOption(chartOptions);
    }
  }, [option, settings, chartRef, period, chartData, hideLegend]);
  // Whenever theme changes we need to add option and
  // setting due to it being deleted in cleanup function

  useEffect(() => {
    // Update chart
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current);
      loading === true ? chart.showLoading() : chart.hideLoading();
    }
  }, [loading, theme, chartRef]);

  return <div ref={chartRef} style={{ width: '100%', height: '500px', ...style }} />;
}

export default StackedBarChart;
