import * as Yup from 'yup';

const basicSchema = {
  username: Yup.string().max(50, 'First name must be at most 50 characters'),
  email: Yup.string().email('Please enter a valid email address'),
  oldPassword: Yup.string(),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .max(32, 'Password must be at most 32 characters')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,32}$/,
      'The password must contain at least one small letter, one capital letter and one digit',
    ),
  password_confirm: Yup.string().oneOf(
    [Yup.ref('password')],
    'Both passwords are not matching',
  ),
  new_password_confirm: Yup.string().oneOf(
    [Yup.ref('new_password')],
    'Both passwords are not matching',
  ),
  message: Yup.string().max(1000, 'Message must be at most 1000 characters'),
  // .required("Confirm password is required"),
};

export default basicSchema;
