import Api from '../../utils/Api';
import adminApiURL from '../../constants/adminApiURL';
import sliceCompanies from '../slices/companies';
import { dispatchAction } from '../store';

const actionsCompanies = {};

actionsCompanies.getCompanies = ({
  q = '',
  page = 1,
  size = 5,
  orderBy = 'code',
  desc = true,
}) => Api.get({
  url: adminApiURL.getCompanies({
    q,
    page,
    size,
    order_by: orderBy,
    desc,
  }),
  action: sliceCompanies.actions.setCompanies,
  withHeaders: true,
})
  .then((response) => {
    dispatchAction(sliceCompanies.actions.setCount, Number(response.headers['x-total-items']));
  });

actionsCompanies.getCompanyByCode = (code) => Api.get({
  url: adminApiURL.getCompanyByCode(code),
  action: sliceCompanies.actions.setSelectedCompany,
});

export default actionsCompanies;
