import { reducerAuth } from './sliceAuth';
import { reducerUsers } from './users';
import { reducerAssets } from './sliceAssets';
import { reducerProfile } from './sliceProfile';
import { reducerAdminAuth } from './sliceAdminAuth';
import { reducerCompanies } from './companies';
import { reducerCustomCharts } from './customCharts';
import { reducerCalendar } from './sliceCalendar';

const reducers = {
  auth: reducerAuth,
  authAdmin: reducerAdminAuth,
  assets: reducerAssets,
  profile: reducerProfile,
  users: reducerUsers,
  companies: reducerCompanies,
  customCharts: reducerCustomCharts,
  calendar: reducerCalendar,
};

export default reducers;
