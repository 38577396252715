import Api from '../../utils/Api';
import adminApiURL from '../../constants/adminApiURL';
import sliceCustomCharts from '../slices/customCharts';
import { dispatchAction } from '../store';

const actionsCustomCharts = {};

actionsCustomCharts.getChartTypes = () => Api.get({
  url: adminApiURL.getChartTypes(),
  action: sliceCustomCharts.actions.setChartTypes,
});

actionsCustomCharts.getCustomCharts = ({
  q = '',
  page = 1,
  size = 5,
  orderBy = 'id',
  desc = true,
}) => Api.get({
  url: adminApiURL.getCustomCharts({
    q,
    page,
    size,
    order_by: orderBy,
    desc,
  }),
  action: sliceCustomCharts.actions.setCustomCharts,
  withHeaders: true,
})
  .then((response) => {
    dispatchAction(sliceCustomCharts.actions.setCount, Number(response.headers['x-total-items']));
  });

actionsCustomCharts.getCustomChartById = (id) => Api.get({
  url: adminApiURL.getCustomChartById(id),
  action: sliceCustomCharts.actions.setSelectedCustomChart,
});

actionsCustomCharts.createCustomChart = (data) => Api.post({
  url: adminApiURL.createCustomChart(),
  requestData: data,
});

actionsCustomCharts.updateCustomChart = (id, data) => Api.put({
  url: adminApiURL.updateCustomChartById(id),
  requestData: data,
});

actionsCustomCharts.deleteChart = (id) => Api.delete({
  url: adminApiURL.deleteCustomChartById(id),
});

export default actionsCustomCharts;
