import Api from '../../utils/Api';
import { removeHeadersByRole, ROLES, setAuthHeader } from '../../utils/apiService';
import adminApiURL from '../../constants/adminApiURL';
import sliceAdminAuth from '../slices/sliceAdminAuth';

const actionsAdminAuth = {};

actionsAdminAuth.signIn = ({
  remember,
  password,
  email,
}) => Api.post({
  url: adminApiURL.authSignIn(),
  action: sliceAdminAuth.actions.signIn,
  requestData: {
    password,
    email,
  },
}).then((response) => {
  const tokenAccess = response.access_token;
  const tokenRefresh = response.refresh_token;
  setAuthHeader(
    {
      token: tokenAccess,
      tokenRefresh,
    },
    remember,
    ROLES.admin,
  );
  removeHeadersByRole(ROLES.user);
  return response;
});

actionsAdminAuth.signOut = (token) => Api.post({
  url: adminApiURL.logout(),
  action: sliceAdminAuth.actions.signOut,
  requestData: {
    refresh_token: token,
  },
}).then(() => {
  removeHeadersByRole(ROLES.admin);
});

export default actionsAdminAuth;
