import React from 'react';

function PersonIcon() {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_0_268)">
        <path fillRule="evenodd" clipRule="evenodd" d="M24.8 23.5C23.9031 23.5 23.4719 24 22 24C20.5281 24 20.1 23.5 19.2 23.5C16.8812 23.5 15 25.3813 15 27.7V28.5C15 29.3281 15.6719 30 16.5 30H27.5C28.3281 30 29 29.3281 29 28.5V27.7C29 25.3813 27.1187 23.5 24.8 23.5ZM27.5 28.5H16.5V27.7C16.5 26.2125 17.7125 25 19.2 25C19.6562 25 20.3969 25.5 22 25.5C23.6156 25.5 24.3406 25 24.8 25C26.2875 25 27.5 26.2125 27.5 27.7V28.5ZM22 23C24.4844 23 26.5 20.9844 26.5 18.5C26.5 16.0156 24.4844 14 22 14C19.5156 14 17.5 16.0156 17.5 18.5C17.5 20.9844 19.5156 23 22 23ZM22 15.5C23.6531 15.5 25 16.8469 25 18.5C25 20.1531 23.6531 21.5 22 21.5C20.3469 21.5 19 20.1531 19 18.5C19 16.8469 20.3469 15.5 22 15.5Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_0_268">
          <rect width="44" height="44" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default PersonIcon;
