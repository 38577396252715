import React from 'react';
import AdminLayout from '../../components/AdminLayout';
import FormCreateCustomProp from '../../components/forms/CreateCustomProp';

function CreateCustomProp() {
  return (
    <AdminLayout>
      <FormCreateCustomProp />
    </AdminLayout>
  );
}

CreateCustomProp.propTypes = {};
export default CreateCustomProp;
