import { createSlice } from '@reduxjs/toolkit';
import { KEY_ADMIN_TOKEN } from '../../utils/apiService';

const initialState = {
  profile: {},
  isAuthorized: !!localStorage.getItem(KEY_ADMIN_TOKEN),
};

const sliceAdminAuth = createSlice({
  name: 'authAdmin',
  initialState,
  reducers: {
    signIn(state, action) {
      state.profile = { ...state.profile, ...action.payload.user };
      state.isAuthorized = true;
    },
    signOut(state) {
      state.isAuthorized = false;
      state.profile = {};
    },
  },
  extraReducers: {},
});

export const reducerAdminAuth = sliceAdminAuth.reducer;
export default sliceAdminAuth;
