const adminRoutes = {
  HOMEPAGE: '/admin/',
  SIGN_IN: '/admin/sign-in/',
  USERS: '/admin/users/',
  COMPANIES: '/admin/companies/',
  COMPANY: '/admin/companies/:id',
  CUSTOM_CHARTS: '/admin/custom-charts/',
  CREATE_CUSTOM_CHART: '/admin/create-custom-chart/',
  CREATE_CUSTOM_CHART_COMPANY: '/admin/create-custom-chart/:id',
  EDIT_CUSTOM_CHART: '/admin/edit-custom-chart/:id',
};

export default adminRoutes;
