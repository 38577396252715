/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  calendar: {},
};

const sliceCalendar = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    setCalendar(state, action) {
      state.calendar = action.payload;
    },
  },
});

export const selectorsCalendar = {
  get: (state) => state.profile,
  calendar: (state) => state.calendar.calendar,
};

export const reducerCalendar = sliceCalendar.reducer;
export default sliceCalendar;
