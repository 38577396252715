/* eslint-disable react/prop-types */
import * as React from 'react';
import { useCallback } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { Button, Chip } from '@mui/material';
import Typography from '@mui/material/Typography';
import { toast } from 'react-toastify';
import actionsUsers from '../../redux/actions/users';
import { ModalContext } from '../../context/ModalContext';

import styles from './styles.module.scss';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(
  order,
  orderBy,
) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    order,
    orderBy,
    onRequestSort,
    columns,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow className={styles.tableRow}>
        {columns.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sort ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.name}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : headCell.name}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function EditUserModal() {
  const {
    props,
    hideModal,
  } = React.useContext(ModalContext);
  const { row } = props;

  const handleSubmit = useCallback(
    (id, values) => {
      actionsUsers
        .updateUser(id, values)
        .then(() => {
          actionsUsers.getUsers({ size: 5 });
          toast.success('User has been successfully updated!');
          hideModal();
        })
        .catch((error) => {
          if (error?.detail) {
            toast.error(error?.detail);
          }
        });
    },
    [hideModal],
  );

  return (
    <>
      <Typography variant="h4">
        Edit User
      </Typography>
      <TableRow key={row.name}>
        <TableCell align="center">{row.name}</TableCell>
        <TableCell align="center">{row.email}</TableCell>

        <TableCell align="center">
          {
            row.is_admin
              ? <Chip label="Admin" color="secondary" />
              : <Chip label="User" color="primary" />
          }
        </TableCell>
        <TableCell align="center">
          {
            row.is_verified
              ? <Chip label="Verified" color="success" />
              : <Chip label="Not verified" color="error" />
          }
        </TableCell>
        <TableCell align="center">
          {
            row.is_active
              ? <Chip label="Active" color="success" />
              : <Chip label="Inactive" color="error" />
          }
        </TableCell>
      </TableRow>

      <div className={styles.buttonsWrapper}>
        <Button
          color={row.is_active ? 'error' : 'primary'}
          margin="center"
          variant="contained"
          onClick={() => handleSubmit(row.id, { is_active: !row.is_active })}
        >
          {row.is_active ? 'Deactivate' : 'Activate'}
        </Button>
        <Button
          color={row.is_verified ? 'error' : 'primary'}
          margin="center"
          variant="contained"
          onClick={() => handleSubmit(row.id, { is_verified: !row.is_verified })}
        >
          {row.is_verified ? 'Undo verify' : 'Verify'}
        </Button>
        <Button
          color={row.is_admin ? 'primary' : 'secondary'}
          margin="center"
          variant="contained"
          onClick={() => handleSubmit(row.id, { is_admin: !row.is_admin })}
        >
          {row.is_admin ? 'Make user' : 'Make admin'}
        </Button>
      </div>
    </>
  );
}

export default function UsersTable({
  users,
  columns,
}) {
  const { showModal } = React.useContext(ModalContext);

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('title');

  const handleRequestSort = (
    event,
    property,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <TableContainer>
        <Table
          sx={{ minWidth: 1050 }}
          aria-labelledby="tableTitle"
          size="medium"
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            columns={columns}
          />
          <TableBody>
            {stableSort(users, getComparator(order, orderBy))
              .map((row) => (
                <TableRow className={styles.tableRow} key={row.id}>
                  <TableCell align="center">{row.name}</TableCell>
                  <TableCell align="center">{row.email}</TableCell>
                  <TableCell align="center">
                    {
                      row.is_admin
                        ? <Chip label="admin" color="secondary" />
                        : <Chip label="user" color="primary" />
                    }
                  </TableCell>
                  <TableCell align="center">
                    {
                      row.is_verified
                        ? <Chip label="true" color="success" />
                        : <Chip label="false" color="error" />
                    }
                  </TableCell>
                  <TableCell align="center">
                    {
                      row.is_active
                        ? <Chip label="true" color="success" />
                        : <Chip label="false" color="error" />
                    }
                  </TableCell>
                  <TableCell align="center">
                    {row.date_join && new Date(row.date_join).toLocaleDateString()}
                  </TableCell>

                  <TableCell align="center">
                    <Button
                      color="primary"
                      margin="center"
                      variant="contained"
                      onClick={() => showModal(EditUserModal, { row })}
                    >
                      Edit
                    </Button>
                  </TableCell>

                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
