import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  customCharts: [],
  chartTypes: [],
  count: 0,
  selectedPage: 1,
  selectedCustomChart: null,
};

const sliceCustomCharts = createSlice({
  name: 'customCharts',
  initialState,
  reducers: {
    setCustomCharts(state, action) {
      state.customCharts = action.payload;
    },
    setCount(state, action) {
      state.count = action.payload;
    },
    setSelectedPage(state, action) {
      state.selectedPage = action.payload;
    },
    setChartTypes(state, action) {
      state.chartTypes = action.payload;
    },
    setSelectedCustomChart(state, action) {
      state.selectedCustomChart = action.payload;
    },
  },
});

export const reducerCustomCharts = sliceCustomCharts.reducer;
export default sliceCustomCharts;
