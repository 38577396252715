/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
// import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import sliceAssets, { selectorsAsssets } from '../../redux/slices/sliceAssets';
import actionsAssets from '../../redux/actions/assets';
import { dispatchAction } from '../../redux/store';

// eslint-disable-next-line react/prop-types
// eslint-disable-next-line no-unused-vars
function AutoCompleteAssetsField({ onChange, size, fontStyle, disabled }) {
  const [open, setOpen] = React.useState(false);
  const [values, setValues] = React.useState(null);
  const [isLoading, setIsloading] = useState(false);
  const searchResults = useSelector(selectorsAsssets.autocompleteResults);

  // const changeHandler = (event, value, reason) => {
  //   if (reason === 'select-option') {
  //     input.onChange(value);
  //   }
  // };

  const onCloseHandler = () => {
    setOpen(false);
    dispatchAction(sliceAssets.actions.resetSearch);
  };

  const inputChangeHandler = async (event, value, reason) => {
    if (reason === 'input') {
      setIsloading(true);
      await actionsAssets.searchAssets(value);
      setIsloading(false);
    }
    if (reason === 'reset') {
      setValues(null);
    }
  };

  // const optionLabel = (option) => {
  //   console.log(option);
  //   return option && (
  //   <div style={{ display: 'flex', flexDirection: 'column' }}>
  //     <Typography
  //       variant="subtitle1"
  //       style={{
  //         fontWeight: 500, color: '#000000', marginBottom: '2px', lineHeight: 1.5,
  //       }}
  //     >
  //       {option.key}
  //       :
  //     </Typography>
  //   </div>
  //   );
  // };
  console.log(disabled);
  return (
    <Autocomplete
      id="assest_autocomplete"
      style={{ width: '100%' }}
      open={open}
      disabled={disabled}
      noOptionsText="Start typing"
      onOpen={() => {
        setOpen(true);
      }}
      ListboxProps={{
        sx: { fontSize: 12 },
      }}
      // className={fontStyle}
      blurOnSelect
      onClose={onCloseHandler}
      isOptionEqualToValue={(option, value) => option.code === value.code}
      getOptionLabel={(option) => `${option.code}: ${option.name}`}
      // key={`autocomplete_${market}`}
      options={searchResults}
      loading={isLoading}
      sx={{
        '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
          fontSize: 12,
        },
      }}
      onChange={(event, value) => onChange(value.code)}
      clearOnEscape
      classes={{ input: fontStyle }}
      value={values}
      onInputChange={inputChangeHandler}
      // onChange={changeHandler}
      // renderOption={(option) => optionLabel(option)}
      renderInput={(params) => (
        <TextField
          {...params}
          size={size}
          disabled={disabled}
          label="Add..."
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

export default AutoCompleteAssetsField;
