/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/prop-types */
import React, { Component, createContext } from 'react';

export const ModalContext = createContext({
  component: null,
  isOpen: false,
  fullScreen: true,
  props: {},
  showModal: () => {},
  hideModal: () => {},
});

export class ModalProvider extends Component {
  constructor(props) {
    // Required step: always call the parent class' constructor
    super(props);

    // Set the state directly. Use props if necessary.
    this.state = {
      component: null,
      isOpen: false,
      fullScreen: true,
      props: {},
      showModal: (component, pr = {}, fullScreen = true) => {
        this.setState({
          isOpen: true,
          fullScreen,
          component,
          props: pr,
        });
      },
      hideModal: () => this.setState({
        isOpen: false,
        component: null,
        fullScreen: true,
        props: {},
      }),
    };
  }

  render() {
    return (
      <ModalContext.Provider value={this.state}>
        {this.props.children}
      </ModalContext.Provider>
    );
  }
}

export const ModalConsumer = ModalContext.Consumer;
