const lineOptions = (yaxis, xaxis, title, hideLegend, unit, isSmooth) => {
  const baseOptions = {
    color: [
      '#5297D4',
      '#E76051',
      '#E4C15A',
      '#64C686',
      '#BA5DD6',
    ],
    title: {
      text: title,
    },
    tooltip: {
      trigger: 'axis',
    },
    legend: {
      show: !hideLegend,
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    toolbox: {
      show: false,
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: xaxis?.data || ['FY18 Q4', 'FY18 Q4', 'FY18 Q4', 'FY18 Q4', 'FY18 Q4', 'FY18 Q4', 'FY18 Q4', 'FY18 Q4', 'FY18 Q4', 'FY18 Q4', 'FY18 Q4', 'FY18 Q4', 'FY18 Q4', 'FY18 Q4'],
      axisTick: { alignWithLabel: true },
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter(value) {
          return `${Intl.NumberFormat('en-GB', {
            notation: 'compact',
          }).format(value)} ${unit || '$'}`;
        },
      },
    },
    series: [],
  };
  if (yaxis && yaxis.length > 0) {
    yaxis.map((item) => baseOptions.series.push({
      name: item.label,
      type: 'line',
      smooth: isSmooth,
      tooltip: {
        valueFormatter(value) {
          return `${Intl.NumberFormat('en-GB', {
            notation: 'compact',
            maximumFractionDigits: 2,
          }).format(value)} ${unit || '$'}`;
        },
      },
      data: item.data || [120, 132, 101, 134, 90, 230, 210, 120, 132, 101, 134, 90, 230, 210],

    }));
  } else {
    baseOptions.series.push({
      name: 'Price',
      type: 'line',
      smooth: isSmooth,
      tooltip: {
        valueFormatter(value) {
          return `${Intl.NumberFormat('en-GB', {
            notation: 'compact',
            maximumFractionDigits: 2,
          }).format(value)} ${unit || '$'}`;
        },
      },
      data: [120, 132, 101, 134, 90, 230, 210, 120, 132, 101, 134, 90, 230, 210],

    });
  }
  return baseOptions;
};

export default lineOptions;
