import React from 'react';

function BurgerIcon() {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_0_259)">
        <path fillRule="evenodd" clipRule="evenodd" d="M28.625 17.875H15.375C15.1679 17.875 15 17.7071 15 17.5V16.5C15 16.2929 15.1679 16.125 15.375 16.125H28.625C28.8321 16.125 29 16.2929 29 16.5V17.5C29 17.7071 28.8321 17.875 28.625 17.875ZM28.625 22.875H15.375C15.1679 22.875 15 22.7071 15 22.5V21.5C15 21.2929 15.1679 21.125 15.375 21.125H28.625C28.8321 21.125 29 21.2929 29 21.5V22.5C29 22.7071 28.8321 22.875 28.625 22.875ZM28.625 27.875H15.375C15.1679 27.875 15 27.7071 15 27.5V26.5C15 26.2929 15.1679 26.125 15.375 26.125H28.625C28.8321 26.125 29 26.2929 29 26.5V27.5C29 27.7071 28.8321 27.875 28.625 27.875Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_0_259">
          <rect width="44" height="44" fill="white" />
        </clipPath>
      </defs>
    </svg>

  );
}

export default BurgerIcon;
