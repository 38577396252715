import basicSchema from './basicSchema';

const authSchema = {
  name: basicSchema.username,
  email: basicSchema.email,
  password: basicSchema.password,
  password_confirm: basicSchema.password_confirm,
  old_password: basicSchema.password,
  new_password: basicSchema.password,
  new_password_confirm: basicSchema.new_password_confirm,
};

export const signInSchema = {
  email: basicSchema.email,
};

export default authSchema;
