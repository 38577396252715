import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ScrollToTop from '../components/ScrollToTop';
import routes from '../constants/routes';
import adminRoutes from '../constants/adminRoutes';
import CompanyPage from '../pages/CompanyPage';
import Homepage from '../pages/Homepage';
import VerifyEmail from '../pages/VerifyEmail';
import SignInAdminPage from '../pages/SignInAdminPage';
import UsersList from '../pages/UsersList';
import CompaniesList from '../pages/CompaniesList';
import { adminAuthSelectors } from '../redux/selectors/adminAuth';
import AdminCompany from '../pages/AdminCompany';
import CreateCustomProp from '../pages/CreateCustomProp';
import CreateCustomPropCompany from '../pages/CreateCustomPropCompany';
import NewsPage from '../pages/News';
import { selectorsAuth } from '../redux/slices/sliceAuth';
import AdminCustomCharts from '../pages/AdminCustomCharts';
import EditCustomProp from '../pages/EditCustomProp';
import AllCompanies from '../pages/AllCompanies';
import ComparePage from '../pages/Compare';
import CalendarPage from '../pages/Calendar';
import Account from '../pages/Account';

// TODO add private route when configure token.
function AppRoutes() {
  const isAuthAdmin = useSelector(adminAuthSelectors.getIsAuth);
  const isAuth = useSelector(selectorsAuth.isAuth);

  return (
    <ScrollToTop>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path={routes.CONFIRM_EMAIL} element={<VerifyEmail />} />
        <Route path={routes.COMPANY_PAGE} element={<CompanyPage />} />
        <Route path={routes.COMPANIES_PAGE} element={<AllCompanies />} />
        <Route path={routes.COMPARE_PAGE} element={<ComparePage />} />
        <Route path={routes.CALENDAR_PAGE} element={<CalendarPage />} />
        <Route
          path={routes.ACCOUNT}
          element={
            isAuth
              ? <Account />
              : <Navigate to={{ pathname: routes.HOME_PAGE }} />
          }
        />
        <Route
          path={routes.NEWS_PAGE}
          element={
            isAuth
              ? <NewsPage />
              : <Navigate to={{ pathname: routes.HOME_PAGE }} />
          }

        />

        <Route
          path={adminRoutes.SIGN_IN}
          element={
            isAuthAdmin
              ? <Navigate to={{ pathname: adminRoutes.USERS }} />
              : <SignInAdminPage />
          }
        />

        <Route
          path={adminRoutes.HOMEPAGE}
          element={
            isAuthAdmin
              ? <Navigate to={{ pathname: adminRoutes.USERS }} />
              : <SignInAdminPage />
          }
        />

        <Route
          path={adminRoutes.USERS}
          element={
            isAuthAdmin
              ? <UsersList />
              : <Navigate to={{ pathname: adminRoutes.SIGN_IN }} />
          }
        />

        <Route
          path={adminRoutes.COMPANIES}
          element={
            isAuthAdmin
              ? <CompaniesList />
              : <Navigate to={{ pathname: adminRoutes.SIGN_IN }} />
          }
        />

        <Route
          path={adminRoutes.COMPANY}
          element={
            isAuthAdmin
              ? <AdminCompany />
              : <Navigate to={{ pathname: adminRoutes.SIGN_IN }} />
          }
        />

        <Route
          path={adminRoutes.CUSTOM_CHARTS}
          element={
            isAuthAdmin
              ? <AdminCustomCharts />
              : <Navigate to={{ pathname: adminRoutes.SIGN_IN }} />
          }
        />

        <Route
          path={adminRoutes.CREATE_CUSTOM_CHART}
          element={
            isAuthAdmin
              ? <CreateCustomProp />
              : <Navigate to={{ pathname: adminRoutes.SIGN_IN }} />
          }
        />

        <Route
          path={adminRoutes.EDIT_CUSTOM_CHART}
          element={
            isAuthAdmin
              ? <EditCustomProp />
              : <Navigate to={{ pathname: adminRoutes.SIGN_IN }} />
          }
        />

        <Route
          path={adminRoutes.CREATE_CUSTOM_CHART_COMPANY}
          element={
            isAuthAdmin
              ? <CreateCustomPropCompany />
              : <Navigate to={{ pathname: adminRoutes.SIGN_IN }} />
          }
        />
      </Routes>
    </ScrollToTop>
  );
}

export default AppRoutes;
