/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import AppBar from '@mui/material/AppBar';
import Fade from '@mui/material/Fade';
// import Button from '@mui/material/Button';
import { ModalConsumer } from '../../context/ModalContext';
import styles from './styles.module.scss';

const Transition = React.forwardRef((props, ref) => <Fade ref={ref} {...props} />);

function ModalRoot() {
  return (
    <ModalConsumer>
      {({ component: Component, hideModal, isOpen, props, fullScreen }) => (
        <Dialog
          fullScreen={fullScreen}
          open={isOpen}
          onClose={hideModal}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: 'relative' }} className={styles.toolbarWrapper}>
            <div className={styles.toolbar}>
              <div>
                <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_0_105)">
                    <rect x="0.5" y="0.5" width="43" height="43" rx="7.5" fill="#282829" stroke="#282829" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M14 22.9279L29.715 22.0721L22.7581 20.4682L26.2066 9L14 22.9279Z" fill="white" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M21.5049 24.5317L17.5081 36L29.7148 22.072L21.5049 24.5317Z" fill="white" />
                  </g>
                  <defs>
                    <clipPath id="clip0_0_105">
                      <rect width="44" height="44" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <IconButton onClick={() => hideModal()}>
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M15.3918 13L20.8619 7.52985C21.1783 7.21348 21.1783 6.70008 20.8619 6.38321L19.5909 5.11215C19.2745 4.79579 18.7611 4.79579 18.4442 5.11215L12.9746 10.5828L7.50444 5.11266C7.18807 4.79629 6.67467 4.79629 6.3578 5.11266L5.08725 6.38321C4.77088 6.69958 4.77088 7.21297 5.08725 7.52985L10.5574 13L5.08725 18.4702C4.77088 18.7865 4.77088 19.2999 5.08725 19.6168L6.35831 20.8879C6.67467 21.2042 7.18807 21.2042 7.50495 20.8879L12.9746 15.4172L18.4447 20.8874C18.7611 21.2037 19.2745 21.2037 19.5914 20.8874L20.8624 19.6163C21.1788 19.2999 21.1788 18.7865 20.8624 18.4697L15.3918 13Z" fill="#282829" />
                </svg>

              </IconButton>
            </div>
          </AppBar>
          <div className={styles.content}>
            {Component && <Component hideModal={hideModal} {...props} />}
            {' '}
          </div>
        </Dialog>
      )}
    </ModalConsumer>
  );
}

export default ModalRoot;
