/* eslint-disable max-len */
import Api from '../../utils/Api';
import apiURL from '../../constants/apiURL';
import sliceCalendar from '../slices/sliceCalendar';

const actionsCalendar = {};

actionsCalendar.getList = ({ dateFrom, dateTo }) => Api.get({
  url: apiURL.getCalendar({ dateFrom, dateTo }),
  action: sliceCalendar.actions.setCalendar,
});

export default actionsCalendar;
