import React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

function SimpleLayout({ children }) {
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Box
        className={styles.content}
        component="main"
      >
        {children}
      </Box>
    </Box>
  );
}

SimpleLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default SimpleLayout;
