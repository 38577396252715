/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
// import debounce from 'lodash/debounce';
// import TextField from '@mui/material/TextField';
// import InputAdornment from '@mui/material/InputAdornment';
import { useSelector } from 'react-redux';
import Layout from '../../components/Layout';
import UITable from '../../components/Table';
// import PropTypes from 'prop-types';
// import cn from 'classnames';
// import styles from './styles.module.scss';
import actionsAssets from '../../redux/actions/assets';
import { selectorsAsssets } from '../../redux/slices/sliceAssets';
import { selectorsAuth } from '../../redux/slices/sliceAuth';
import { SORTING } from '../../constants/api';

function Homepage() {
  const assetsList = useSelector(selectorsAsssets.list);
  const isAuth = useSelector(selectorsAuth.isAuth);
  const [search, setSearch] = useState('');
  const [sorting, setSorting] = useState({
    orderBy: SORTING.MARKET,
    isDesc: true,
  });
  useEffect(() => {
    actionsAssets.getList({});
  }, [isAuth]);

  // const searchFunction = (string) => {
  //   actionsAssets.getList({
  //     query: string,
  //     size: 20,
  //     orderBy: sorting.orderBy,
  //     isDesc: sorting.isDesc,
  //   });
  //   setSearch(string);
  // };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const debounceSearch = useCallback(debounce(searchFunction, 500), []);
  // const onSearchHandler = useCallback((string) => {
  //   debounceSearch(string);
  // }, [debounceSearch]);

  const sortHandler = useCallback((orderBy, isDesc) => {
    actionsAssets.getList({
      query: search,
      orderBy,
      isDesc,
    });
    setSorting({ orderBy, isDesc });
  }, [search]);
  return (
    <Layout>
      <>
        {/* <TextField
          className={styles.input}
          fullWidth
          name="search"
          placeholder="search"
          onChange={(event) => onSearchHandler(event.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_0_815)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M22.8596 21.6246L19.0733 17.8382C19.0016 17.7665 18.908 17.729 18.8082 17.729H18.3965C19.3789 16.5906 19.9747 15.1092 19.9747 13.4873C19.9747 9.9037 17.071 7 13.4873 7C9.9037 7 7 9.9037 7 13.4873C7 17.071 9.9037 19.9747 13.4873 19.9747C15.1092 19.9747 16.5906 19.3789 17.729 18.3965V18.8082C17.729 18.908 17.7696 19.0016 17.8382 19.0733L21.6246 22.8596C21.7712 23.0062 22.0082 23.0062 22.1548 22.8596L22.8596 22.1548C23.0062 22.0082 23.0062 21.7712 22.8596 21.6246ZM13.4873 18.4776C10.7302 18.4776 8.49708 16.2444 8.49708 13.4873C8.49708 10.7302 10.7302 8.49708 13.4873 8.49708C16.2444 8.49708 18.4776 10.7302 18.4776 13.4873C18.4776 16.2444 16.2444 18.4776 13.4873 18.4776Z" fill="#A7A7A9" />
                  </g>
                  <defs>
                    <clipPath id="clip0_0_815">
                      <rect width="30" height="30" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </InputAdornment>
            ),
          }}
        /> */}
        {assetsList?.length > 0 && (
        <UITable
          noSort
          data={assetsList}
          isAuth={isAuth}
          onSort={(orderBy, isDesc) => sortHandler(orderBy, isDesc)}
        />
        )}
      </>

    </Layout>

  );
}

Homepage.propTypes = {

};
export default Homepage;
